import React, { Component } from "react";
// import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import Checkbox from "@material-ui/core/Checkbox";

import LoginForm from "./loginForm";
import SignUpForm from "./signUpForm";
import SignUpMessage from "./signUpMessage";
import GoBackToLogin from "./goBackToLogin";
import "../../styles/login.scss";

class Login extends Component {
  state = {
    showLogin: true,
    redirectToReferrer: false
  };

  componentDidMount() {
    if(this.props.location.state && this.props.location.state.userLog) {
      window.location.reload();
    }
    // document.getElementById("goBackToLogin").style.display = "none";
    // document.getElementById("signUpForm").style.display = "none";
    // document.getElementById("invalid").style.display = "none";
  }

  signUpBtnClicked = () => {
    // console.log("sign up button clicked");
    // document.getElementById("loginForm").style.display = "none";
    // document.getElementById("signUpWrapper").style.display = "none";
    // document.getElementById("signUpForm").style.display = "block";
    // document.getElementById("goBackToLogin").style.display = "block";
    this.setState({ showLogin: !this.state.showLogin });
  };

  goBackToLoginClicked() {
    // console.log("time to display login");
    document.getElementById("signUpForm").style.display = "none";
    document.getElementById("goBackToLogin").style.display = "none";
    document.getElementById("loginForm").style.display = "block";
    document.getElementById("signUpWrapper").style.display = "block";
  }

  changeStateFromChild = value => {
    this.setState({
      showLogin: value
    });
  };

  render() {
    // const { formErrors } = this.state;

    return (
      <div className="wrapper">
        <div className="loginInfoSection">
          {/* <div className="information">
            <div>
              <img src={require("../../assets/dummy.png")} alt="dummy" />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur Eum quasi quidem quibusdam.Lorem ipsum dolor
                sit amet, consectetur adipisicing elit.
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                className="learnMore"
              >
                Learn More
              </Button>
            </div>
          </div> */}

          <div className="loginSignupSection">
            <div id="login" className="form-wrapper">
              {this.state.showLogin ? (
                localStorage.getItem("logToDashboard") === "true" ? (
                  <React.Fragment>
                    <LoginForm
                      handleLoginSubmit={this.handleLoginSubmit}
                      handleChange={this.handleChange}
                      id="loginForm"
                      directTo="dashboard"
                    />
                    {/* <Typography variant="overline" display="block" className="or">
                      Don't Have an account? <span className="register" onClick={this.signUpBtnClicked}>Sign Up</span>
                    </Typography> */}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <LoginForm
                      handleLoginSubmit={this.handleLoginSubmit}
                      handleChange={this.handleChange}
                      id="loginForm"
                      directTo="home"
                    />
                    {/* <Typography variant="overline" display="block" className="or">
                      Don't Have an account? <span className="register" onClick={this.signUpBtnClicked}>Sign Up</span>
                    </Typography> */}
                  </React.Fragment>
                )
              )
              : (
                <React.Fragment>
                  <SignUpForm
                    handleSignUpSubmit={this.handleSignUpSubmit}
                    handleChange={this.handleChange}
                    id="signUpForm"
                    callback={this.changeStateFromChild}
                    directTo="login"
                  />
                  <div style={{ height: 16 }}></div>
                  <Typography variant="overline" display="block">
                    <span className="register" onClick={this.signUpBtnClicked}>Go Back To Login</span>
                  </Typography>
                </React.Fragment>
              )  
            }
            </div>
            {/* <div className="signUp">
              {this.state.showLogin ? (
                <SignUpMessage signUpBtnClicked={this.signUpBtnClicked} />
              ) : (
                <GoBackToLogin signUpBtnClicked={this.signUpBtnClicked} />
              )}
            </div> */}
            
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
