import React, { Component } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: ${props =>props.width};
    height: ${props=>props.height};
`;

export default class Map extends React.Component{
    
    componentDidMount(){
        this.map = L.map('map');
        // ,{
        //     center: [58,45],
        //     zoom: 6,
        //     zoomControl: false

        // });
        L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',{
            attribution: '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors' +
            ', Tiles courtesy of <a href="https://geo6.be/">GEO-6</a>',
            detectRetina: true,
            maxZoom:20,
            maxNativeZoom:17,
            

        }).addTo(this.map);
        var newMarkerGroup = new L.LayerGroup();
        this.map.on('click', L.marker(L.latLng(27.7420, 85.3432), 12));


        this.map.setView(new L.LatLng(27.7420, 85.3432), 12);

        L.marker(new L.LatLng(27.7420, 85.3432), 12).addTo(this.map)
        .bindPopup('New IT Venture')
        .openPopup();
        
        }

        render(){
            return <Wrapper width = "1280px" height='380px' id = 'map' />
        }
    }



