import React, { Component } from 'react';
import axios from 'axios';
import Loader1 from '../loader/loader';
import "../../styles/common.css";
import Grid from "@material-ui/core/Grid";
import { Dimensions} from 'react-native';
import {Button, Modal} from 'react-bootstrap';
import playstore from '../../assets/google-play-store-10.jpg';
import appstore from '../../assets/app-store-icon.png';
import logo from '../../assets/logo.png';
import '../../../src/index.css';
 class musicVideos extends Component {
     constructor(props) {
         super(props);
         this.state={
             videos:[],
             name:'',
             isLoading:false
         };
         
     }
     fetchVideos =() =>{
        this.Setstate={
            videos:[],
            isLoading:false
        };
        axios.get("https://mnm.nettvnepal.com.np/api/net-tv/music-videos")
        .then(res => 
            {
            if(!this.Unmounted){
               // let a= res.data.music-videos;
                this.setState({
                    videos:res.data['music-videos'],
                    isLoading:true
                })
            }
        }
        )
     }

     handleShow = () => {
        this.setState({
            show:true
        })
    
      }
      handleClose = () =>{
          this.setState({
            show:false
          })
      }

      componentDidMount = () =>{
        if(sessionStorage.getItem('videos')){
          // console.log("using data from session storage");
          sessionStorage.getItem('videos') && this.setState({
                videos: JSON.parse(sessionStorage.getItem('videos')),
                isLoading:true
              })
        }
        else{
          this.fetchVideos();
        }
      }
    
      componentWillUpdate(nextProps, nextState) {
        sessionStorage.setItem('videos', JSON.stringify(nextState.videos));
      }
     
        componentWillUnmount = () => {
            this.Unmounted = true
        }

 

    render() {
        const{videos, isLoading} = this.state;

        const  videoData = videos.map(d =>
            <div className="textChange " style={{width: '100%'}}>
            <div className="textChange text-uppercase" style={{justifyContent:'flex-start', fontSize:'1.6rem', fontWeight:'bold', marginBottom:'10px'}} >{d.name}</div>
            <div className="row">
            
            {d.data.data.map(a=>
                <div className="col-lg-3 col-xl-3 col-md-4 col-sm-6 col-6">
                    <div className="geetInfo1 link">
                        {a.logo === undefined?
                            <img className = "resize-musicvideo"
                            onClick={ () => this.handleShow()}
                                style={{height:Dimensions.get('window').height/4, width:'100%'}}
                                src={
                                a.image
                                }
                                alt="musicImage"
                                />
                                
                            :
                            <img className = "resize-musicvideo"
                            onClick={ () => this.handleShow()}
                            style={{height:Dimensions.get('window').height/4, width:'100%'}}
                            src={
                            a.logo
                            } alt = "musicImage"
                            />}
                            <div className="fillContent1">
                    {a.is_purchase === "free" ? 
                    <p style={{marginBottom:'0px'}}>{a.is_purchase}</p> : a.price.map((a) => 
                    <div style={{textAlign:'right'}} >
                       <p style={{marginBottom:'0px'}}> {a.currency} {a.price} </p>
                    </div>
                    )}
                    </div>
                        {a.title === undefined ?
                        <div className="truncate" style={{textAlign:'center'}}><p>{a.name}</p></div>:
                        <div className="truncate cardContent" style={{textAlign:'center'}}><p>{a.title}</p></div>}
                    </div>
                </div>
                )}
                </div>
                <hr/>
            </div>)
        
        // Modal Starts
    if(this.state.show){
        return (
          <div className="modal ">
          <Modal
           show={this.state.show} onHide={()=>this.handleClose()}
           centered
           
          >
          <Modal.Header closeButton >
          <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
          <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
          <div className = "row">
          <div className="col-4">
          </div>
          <div className="col-4">
          <div className = "row">
          <div className="col-6">
          <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
          <img src = {playstore} /> 
          </a>
          </div>
          <div className="col-6">
          <img src = {appstore}/>
          </div>
          </div>
          </div>
          <div className="col-4">
          </div>
          </div>
          <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
          <p style={{textAlign:'center'}}>Scan QR Code</p>
          <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
          <div className="row">
          <div className="col-2 "><img className="modalqr" src = {logo} style={{height:'100px', width:'140px'}}/>
          </div>
          <div className="col-2">
          </div>
          <div className="col-2">
          </div>
          </div>
          </div>
          <div className="col-4"></div>
          </div>
        </Modal.Body>
  
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>this.handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
      )
      }
      //Modal Ends
      
        
        if(isLoading){
          // console.log("musicvideos", this.state.videos)
        return (
            <Grid container spacing={4} className="homepageGridContainer">
            {videoData}
            </Grid>
        )
        }else{
            return <Loader1/>
        }
    }
}
export default musicVideos;
