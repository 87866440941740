import React, {Component} from "react";
import "../../styles/packagesList.scss";
import Typography from '@material-ui/core/Typography';
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Loader1 from "../loader/loader";

class PackagesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            packagesResponse: "",
            paymentResponse: "",
            purchaseOption: "fonepay",
            notLoggedIn: false
        }
    }

    componentDidMount() {
        if(localStorage.getItem("helloToken1")){
        return Axios({
            url: 'https://mnm.nettvnepal.com.np/api/net-tv/packages',
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("helloToken1")
            }
          }).then(
            res => {
              this.setState({
                packagesResponse: res
              })
            },
            err => {
                
                if(err.response.status === 400 ) {
                    // console.log(err.response.status);
                    this.setState({
                        notLoggedIn: true
                    })
                }
            }
          );
    }
    else{
        return Axios({
            url: 'https://mnm.nettvnepal.com.np/api/net-tv/packages',
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("helloToken")
            }
          }).then(
            res => {
              this.setState({
                packagesResponse: res
              })
            },
            err => {
                
                if(err.response.status === 400 ) {
                    // console.log(err.response.status);
                    this.setState({
                        notLoggedIn: true
                    })
                }
            }
          );
    }
}

    fonepayPayment = (pack) => {
        let formdata = new FormData();
        formdata.append("amount", parseInt(pack.price[0].price));
        formdata.append("product_id", pack.id);
        formdata.append("purchase_type", "package");
        formdata.append("time_span", pack.price[0].type);
        formdata.append("duration", pack.price[0].duration);
        if(localStorage.getItem("helloToken1")){
        return Axios({
            url: 'https://mnm.nettvnepal.com.np/api/net-tv/fonepay/request/payment',
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + localStorage.getItem("helloToken1")
            },
            data: formdata
          }).then(
            res => {
              this.setState({
                  paymentResponse: res.data
              })
              localStorage.setItem("fonepayHTML", res.data);
              window.open ('/#/fonepay', "_newtab" );
            },
            err => {
            //   console.log(err);
            }
          )}
          else{
            return Axios({
                url: 'https://mnm.nettvnepal.com.np/api/net-tv/fonepay/request/payment',
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + localStorage.getItem("helloToken")
                },
                data: formdata
              }).then(
                res => {
                  this.setState({
                      paymentResponse: res.data
                  })
                  localStorage.setItem("fonepayHTML", res.data);
                  window.open ('/#/fonepay', "_newtab" );
                },
                err => {
                //   console.log(err);
                }
              )
          }
    }

    esewaPayment = (pack) => {
        var path = "https://uat.esewa.com.np/epay/main";
        var params = {
            amt: parseInt(pack.price[0].price),
            psc: 0,
            pdc: 0,
            txAmt: 0,
            tAmt: pack.price[0].price,
            pid: pack.id,
            scd: "KBwTHgQASwQXAVMoFhIHAl83NV4gJEw+JDMwICAkOSU+KjYtLA==",
            su: "https://localhost:3000/#/esewaSuccess",
            fu: "https://localhost:3000/#/esewaFailure"
        }
        var form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", path);

        for(var key in params) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);
            form.appendChild(hiddenField);
        }
    
        document.body.appendChild(form);
        form.submit();
    }

    ntcPayment = (pack) => {
        let formdata = new FormData();
        formdata.append("phone", 9849123456);
        formdata.append("email", localStorage.getItem("Current"));
        formdata.append("timeSpan", pack.price[0].type);
        formdata.append("duration", pack.price[0].duration);
        formdata.append("productType", "package");
        formdata.append("productId", pack.id);
        // console.log(Array.from(formdata));
        if(localStorage.getItem("helloToken1")){
        return Axios({
            url: 'https://mnm.nettvnepal.com.np/api/net-tv/purchase/package/ntc/otp',
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("helloToken1")
            },
            data: formdata
          }).then(
            res => {
                // console.log(res);
            },
            err => {
            //   console.log(err);
            }
          )}
          else{
            return Axios({
                url: 'https://mnm.nettvnepal.com.np/api/net-tv/purchase/package/ntc/otp',
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("helloToken")
                },
                data: formdata
              }).then(
                res => {
                    // console.log(res);
                },
                err => {
                //   console.log(err);
                }
              )
          }
    }

    purchase = (pack) => {
        if(this.state.purchaseOption === "fonepay") {
            this.fonepayPayment(pack);
        } else if (this.state.purchaseOption === "esewa") {
            this.esewaPayment(pack);
        } else if (this.state.purchaseOption === "ntc") {
            this.ntcPayment(pack);
        }
    }

    purchaseOption = e => {
        const {name, value} = e.target;
        this.setState({
            purchaseOption: value
        })
    }

    render() {
        if(!(localStorage.getItem("helloToken") || localStorage.getItem("helloToken1")) ) {
            return (
                <Grid container spacing={4} className="homepageGridContainer">
                <h3>Please Login To View Subscriptions. </h3>
              </Grid>
            )
        }
        else {
        return(
            <React.Fragment>
                {this.state.packagesResponse.data === undefined ?
                    <Loader1/>
                    :
                    <div className="subscriptions-container">
                                <Typography variant="h4" className="header">
                                    Our Subscriptions
                                </Typography>
                                <div className="subscriptions-list-conatiner">
                                    {this.state.packagesResponse.data.map((subscription, index) =>(
                                        <div className="one-subscription" key={index}>
                                            <Typography variant="h4" gutterBottom>
                                                {subscription.title}
                                            </Typography>
                                            <div className="description">
                                                <Grid container spacing={3}>
                                                    {subscription.packages.map((pack, index) => (
                                                        <Grid item xs={6} key={index}>
                                                            <div className="package-descp">
                                                                <Typography variant="h6">
                                                                    {pack.name} Package
                                                                </Typography>
                                                                <span 
                                                                    dangerouslySetInnerHTML={{
                                                                    __html: pack.description
                                                                }}></span>
                                                                <div className="price">
                                                                    <span>Price: </span>
                                                                    <span>{pack.price[0].currency}{pack.price[0].price}</span>
                                                                </div>
                                                                {/* <Typography variant="subtitle1" gutterBottom>
                                                                    Purchase Using:
                                                                </Typography>
                                                                <select className="purchaseDropdown" onChange={this.purchaseOption}>
                                                                    <option value="fonepay">Fonepay</option>
                                                                    <option value="esewa">Esewa</option>
                                                                    <option value="ntc">NTC</option>
                                                                </select>
                                                                <div style={{ marginTop: "0.75rem" }}>
                                                                    <Button variant="contained" onClick={() => this.purchase(pack)}>Purchase</Button>
                                                                </div> */}
                                                            </div>            
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div> 
                                        </div>
                                    ))}
                                </div>
                    </div>
                }
            </React.Fragment>
        )
        }
    }
}

export default PackagesList;