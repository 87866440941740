import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DropDown from "../DropDown/dropdown";
import { getVideoById } from "../../connectivity/player/api.videoById";
// import ChannelLogo from "../channels/channelLogo";
// import HorizontalMore from "../../common/horizontalMore";
import LoginForm from "../login/loginForm";
import Axios from "axios";
import "../../styles/details.scss";
import "../../styles/textResponsive.scss";
// import { toast } from "react-toastify";

class Details extends Component {
  // state = {
  //   tags: ["#eenie", "#meenie", "#battigayo", "#garmiBhayo"]
  // };

  constructor(props) {
    // console.log("Details constructor");

    super(props);
    // console.log(this.props.details);
    this.state = {
      tags: ["#eenie", "#meenie", "#battigayo", "#garmiBhayo"],
      token: localStorage.getItem("currentUser"),
      like: "",
      dislike: "",
      selectedVideoCategory: "",
      displayModal: false,
      showWithProp: true
    };
  }

  componentDidMount() {
    // console.log("Details componentDidMount()");
    this.setState({
      like: this.props.details.like_count,
      dislike: this.props.details.dislike_count
    });

    return Axios({
      url: process.env.REACT_APP_API_URL + "/categories",
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("currentUser")
      }
    }).then(
      res => {
        this.setState({
          selectedVideoCategory:
            res.data.data[localStorage.getItem("ClickedCategoryVideoID") - 1]
              .title,
          like: this.props.details.like_count
        });
      },
      err => {
        // console.log("ERROR FOR CATEGORIES API", err);
      }
    );
  }

  likeorDislikeClicked = rate => {
    if (localStorage.getItem("currentUser") === "null") {
      // toast.success("You have to Log In to like or dislike a video");
      this.setState({
        displayModal: true
      });
    } else {
      if (rate === "like") {
        this.likeClicked();
      } else if (rate === "dislike") {
        this.dislikeClicked();
      }
    }
  };

  likeClicked = () => {
    // console.log("inside LIKE");
    this.setState({
      showWithProp: false
    });
    return Axios({
      url:
        process.env.REACT_APP_API_URL +
        "/videos/" +
        this.props.details.id +
        "/likes?like=1",
      method: "POST",
      headers: {
        Authorization: "Bearer" + localStorage.getItem("currentUser")
      }
    }).then(
      res => {
        // console.log("Like Response", res);
        getVideoById(this.props.details.id).then(res => {
          // console.log("updated for Likes", res.data);
          this.setState({
            like: res.data.data.like_count,
            dislike: res.data.data.dislike_count
          });
        });
      },
      err => {
        // console.log(err);
      }
    );
  };

  dislikeClicked = () => {
    // console.log("inside DISlike");
    this.setState({
      showWithProp: false
    });
    return Axios({
      url:
        process.env.REACT_APP_API_URL +
        "/videos/" +
        this.props.details.id +
        "/likes?like=0",
      method: "POST",
      headers: {
        Authorization: "Bearer" + localStorage.getItem("currentUser")
      }
    }).then(
      res => {
        // console.log("disLike Response", res);
        getVideoById(this.props.details.id).then(res => {
          // console.log("updated for disLikes", res.data);
          this.setState({
            like: res.data.data.like_count,
            dislike: res.data.data.dislike_count
          });
        });
      },
      err => {
        // console.log(err);
      }
    );
  };

  modalClose = () => {
    this.setState({
      displayModal: false
    });
  };

  render() {
    // console.log("***** Details Render ******");
    return (
      <React.Fragment>
        <div className={!this.state.displayModal ? "modal hide" : "modal show"}>
          <div className="loginModal">
            <div style={{ textAlign: "right" }}>
              <i
                className="material-icons"
                style={{ cursor: "pointer" }}
                onClick={this.modalClose}
              >
                close
              </i>
            </div>
            <LoginForm directTo="samePage" />
          </div>
        </div>
        <Grid container className="videoInfo">
          <Grid item xs={12}>
            <Typography variant="h6">{this.props.details.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            {this.state.tags.map((text, index) => (
              <Typography
                variant="caption"
                display="block"
                key={text}
                color="primary"
              >
                {this.state.tags[index]}
              </Typography>
            ))}
          </Grid>
          <Grid item sm={7} xs={12}>
            <Typography variant="h6">
              {this.props.details.view_count} views
            </Typography>
          </Grid>
          <Grid item sm={5} xs={12} className="likesDislikesGrid">
            <div className="likesDislikesContainer">
              <div>
                <i
                  className="material-icons"
                  onClick={() => this.likeorDislikeClicked("like")}
                >
                  thumb_up
                </i>
                {this.state.showWithProp ? (
                  <Typography variant="subtitle1">
                    {this.props.details.like_count}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">{this.state.like}</Typography>
                )}
              </div>
              <div>
                <i
                  className="material-icons"
                  onClick={() => this.likeorDislikeClicked("dislike")}
                >
                  thumb_down
                </i>
                {this.state.showWithProp ? (
                  <Typography variant="subtitle1">
                    {this.props.details.dislike_count}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">
                    {this.state.dislike}
                  </Typography>
                )}
              </div>
              <IconButton>
                <i className="material-icons">more_horiz</i>
              </IconButton>
            </div>
          </Grid>
          <DropDown description={this.props.details.description} />
        </Grid>
      </React.Fragment>
    );
  }
}

export default Details;
