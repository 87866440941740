import React, { Component } from "react";
import { Button, TextField } from "@material-ui/core";
import { ResetPasswordApi } from "../../connectivity/auth/api.resetPassword";

const queryString = require("query-string");

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   token: "",
      //   email: ""
    };
    this.state.email = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    }).email;
    this.state.token = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    }).token;
  }

  handleSubmit = e => {
    e.preventDefault();
    // console.log(
    //   this.state.email,
    //   this.state.token,
    //   e.target.password.value,
    //   e.target.confirmPassword.value
    // );
    ResetPasswordApi(
      this.state.email,
      this.state.token,
      e.target.password.value,
      e.target.confirmPassword.value
    );
  };
  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <TextField
          label="Password"
          margin="dense"
          variant="outlined"
          type="password"
          name="password"
        />
        <TextField
          label="Confirm Password"
          margin="dense"
          variant="outlined"
          type="password"
          name="confirmPassword"
        />
        <Button type="submit">Submit</Button>
      </form>
    );
  }
}

export default ResetPassword;
