import { getBaseRequestConfig } from "../baseRequestConfig";
import asyncFetch from "../async-fetch";

export async function getVideoById(videoId) {
  const url = process.env.REACT_APP_API_URL + "/videos/" + videoId;

  const baseRequestConfig = getBaseRequestConfig();

  const response = await asyncFetch(url, baseRequestConfig);

  // console.log("Video By Id Response", response);

  return await response;
}
