import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "../../styles/login.scss";
import axios from "axios";
import {PostData} from '../../connectivity/apiLogin';
import {PostPhone} from '../../connectivity/apiPhone';
import { Redirect } from "react-router-dom";
import firebase from 'firebase';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach(val => {
    val === null && (valid = false);
  });

  return valid;
};

firebase.initializeApp({
  apiKey: "AIzaSyC42G3gMdpGEcnfoja9QoIhXg_Oaxcz-hs",
  authDomain: "momo-web.firebaseapp.com",
  databaseURL: "https://momo-web.firebaseio.com",
  projectId: "momo-web",
  storageBucket: "momo-web.appspot.com",
  messagingSenderId: "800298366755",
  appId: "1:800298366755:web:29121b8f2823f440ee3e96",
  measurementId: "G-8HT0PE66N2"
})

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password:null,
      phone:null,
      redirect:false,
      isSignedIn: false,
      statusCheck:false,
      isSubmit: false,
      currentUser:[],
      formErrors: {
        email: "",
        password: "",
      },
      displayPasswordModal: false
    };
    
  }

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
           
    ],
    callbacks: {
      signInSuccess: () => false
    }
  }

  authListener = () =>{
    firebase.auth().onAuthStateChanged(user => {
      //this.login(user)
      if(user){
        let a = user.providerData[0].providerId;
        let b = user.providerData[0].uid;
        let c = user.displayName;
        let d = user.email;
        axios ({
          url: "https://mnm.nettvnepal.com.np/api/net-tv/login/social?email="+d +"&provider_user_id="+b+"&provider="+a+"&first_name="+c,
          method: "GET"
        }).then((result)=>{
          let responseJson = result;
          // console.log("result from socialLogin:", responseJson);
    
          if(responseJson){
                this.setState({
                  social:responseJson.data.token,
                  isLoading:true
                  
                })
                localStorage.setItem("helloToken", (responseJson.data.token))
                localStorage.setItem("Name", (responseJson.data.name))
                localStorage.setItem("Email", (responseJson.data.email))
                localStorage.setItem("firebasePhone", (user.phoneNumber))
                localStorage.setItem("provider", (user.providerData[0].providerId))
                localStorage.setItem("UserId", (user.providerData[0].uid))
              }
          },
          err => {
              this.setState({
                  errorMessage: err
              })
          })
        }
          else{
            this.setState({user:null});
            localStorage.removeItem('user')
          }
  })}

  // login = (res) =>{
  //   console.log("ree", res)
  //   this.state={
  //     name:res.displayName,
  //     email:res.email
  //   }

  // }

  componentDidMount = () => {
    this.authListener();
    //this.handleSocialLogin();
  };

handleChange = e => {
  e.preventDefault();
  const { name, value } = e.target;
  let formErrors = { ...this.state.formErrors };

  switch (name) {
    
    case "email":
      formErrors.email = emailRegex.test(value)
        ? ""
        : "invalid email address";
      break;
    case "password":
      formErrors.password =
        value.length === null ? "Password Required" : "";
      break;
    default:
      break;
  }

  this.setState({ formErrors, [name]: value }, () => console.log(this.state));
};

  handleClick = () =>{
    this.setState({
      isSubmit:true
    })
  }
  handlleSocial = () =>{
    // console.log("tyr i'm called");
  }

handlePhoneSubmit = (e) =>{
  e.preventDefault();
  if(this.state.phone){
      // console.log("data sent for Phone:", this.state);
      PostPhone('login/ntc', this.state).then((result)=>{
      let responseJson = result;
      // console.log("result from Phone api:", responseJson);
      if(responseJson){
            this.setState({
              statusCheck:true,
              responseSuccess: responseJson.token,
              name:responseJson.name,
              telephone:responseJson.telephone,
              responseError:responseJson.error
            })
            localStorage.setItem("currentName", (responseJson.name))
            localStorage.setItem("helloTelephone", (responseJson.telephone))
            localStorage.setItem("helloToken", (responseJson.token))
          }})
  .catch(error =>{
    this.setState({
      alert_message:'error'
    })
  })
}
}

  handleLoginSubmit = (e) =>{
       e.preventDefault();
       if (formValid(this.state)) {
        // console.log(`
        //   --SUBMITTING--
        //   Email: ${this.state.email}
        //   Password: ${this.state.password}
        // `);
      } else {
        // console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
      }

        if(this.state.email && this.state.password){
            //console.log("data sent for login:", this.state);
        PostData('login', this.state).then((result)=>{
          let responseJson = result;
          //localStorage.setItem('currentData',responseJson)

          // console.log("result from Normal Login api:", responseJson);

          if(responseJson){
                this.setState({
                  statusCheck:true,
                  responseSuccess: responseJson.token,
                  name:responseJson.name,
                  email:responseJson.email,
                  image:responseJson.avatar,
                  responseError1:responseJson.message
                  
                })
                localStorage.setItem("Current", (responseJson.email))
                localStorage.setItem("currentName", (responseJson.name))
                localStorage.setItem("helloToken1", (responseJson.token))

     
              }
          }).catch(error =>{
            this.setState({
              alert_message:'error'
            })
          })
    }
}
currentName=()=>{
  return JSON.parse(localStorage.getItem('currentName'))
}

Current=()=>{
  return JSON.parse(localStorage.getItem('Current'))
}

logOut = () =>{
  return localStorage.setItem('Current', null)
}

  render() {
    const { formErrors } = this.state;
    if((this.state.responseSuccess && localStorage.getItem("deviceID")) || (this.state.social && localStorage.getItem("deviceID"))){
      return (
        <div>
        <Redirect to = "/home"/>
        {window.location.replace("/home")}
      </div>
      )}
    else if(this.state.responseSuccess || this.state.social){
              return (
              <div>
              {<Redirect to = "/home"/>}
              {window.location.replace("/home")}
              </div>
              )}
   
    return (
      <React.Fragment> 
       <div>
       <p style={{backgroundColor:'#e93e22', }}>
       {this.state.responseError1 ? 
         <div className="text-capitalize">
        { this.state.responseError1 }</div>:''} 
       </p>
        <form
          onSubmit={this.handleLoginSubmit}
          // autoComplete="off"
        >
          <Typography variant="h4">LOG IN</Typography>
          
        <div className="loginInputFields">
        <TextField
          label="Email"
          value = {this.state.email}
          className={formErrors.email.length > 0 ? "error" : "fields"}
          className="fields"
          margin="normal"
          name="email"
          type="email"
          variant="outlined"
          onChange={this.handleChange}
          required
          
      />
    {formErrors.email.length > 0 && (
    <span className="errorMessage">{formErrors.email}</span>
  )}
      <TextField
        label="Password"
        className="fields"
        className={formErrors.password.length === null ? "error" : "fields"}
        type="password"
        value = {this.state.password}
        autoComplete="current-password"
        margin="normal"
        name="password"
        variant="outlined"
        onChange={this.handleChange}
        required
      />
    {formErrors.password.length > 0 && (
    <span className="errorMessage">{formErrors.password}</span>
  )}
  </div>


          <div className="loginOptions">
            {/* <div>
              <Checkbox
                value="checkedC"
                inputProps={{
                  "aria-label": "uncontrolled-checkbox"
                }}
              />
              <Typography variant="caption">Keep me logged in</Typography>
            </div> */}
            {/* <Typography
              variant="caption"
              className="forgot-password"
              onClick={this.forgotPasswordClicked}
            >
              Forgot Password?
            </Typography> */}
          </div>
          <div className="login">
            <Button
              variant="contained"
              className="momoOrangeBtn"
              type="submit"
              //onClick={this.loginClicked}
              //onClick={(e) => this.handleLoginSubmit(e)}
            >
              Log In
            </Button>
          </div>
          {/* <Typography variant="overline" display="block" className="or">
            or
          </Typography> */}
        
        </form>
        {/* <Button
        variant="contained"
        style={{ backgroundColor:"#03338C", color: "white", justifyContent: "flex-start" }}
        type="submit"
      >
        <img src={require("../../assets/ntc.png")} style={{ width: "20%" }} />Log in with phone
      </Button> */}
        {/* <StyledFirebaseAuth
        uiConfig={this.uiConfig}
        firebaseAuth={firebase.auth()}
          /> */}
            
        </div>
      

      </React.Fragment>
    );
  }
}
export default LoginForm;
