import React, { Component } from "react";
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/common.css";
import "../../styles/videos.css";
import Loader1 from "../loader/loader";
import Grid from "@material-ui/core/Grid";
import {Button, Modal} from 'react-bootstrap';
import playstore from '../../assets/google-play-store-10.jpg';
import appstore from '../../assets/app-store-icon.png';
import logo from '../../assets/logo.png';

class Karaoke extends Component {
  constructor(props){
    super(props);
    this.state= {
      karaoke1:[],
      other:[],
      isLoading:false,
      
    }
  }

  fetchCategories =()=>{
    this.Setstate= {
      karaoke1:[],
      other:[],
      isLoading:false,
      
    }
    axios.get("https://mnm.nettvnepal.com.np/api/net-tv/karaokes")
    .then(res => { if(!this.unMounted){
                     this.setState({
                         karaoke1:res.data.categories,
                         other:res.data.vendors,
                         isLoading:true,
                         isShow:false
                 })
             }
                     })
         .catch((error) =>{
            //  console.log(error);
         }) 
  }

  handleClick = () =>{
    this.setState({
      isShow:true
    })
  }

  handleShow = () => {
    this.setState({
        show:true
    })
}

  handleClose = () =>{
      this.setState({
        show:false
      })
  }
  componentDidMount = () =>{
    if(sessionStorage.getItem('karaoke1') || sessionStorage.getItem('other')){
      // console.log("using data from session storage");
      sessionStorage.getItem('karaoke1') && this.setState({
            karaoke1: JSON.parse(sessionStorage.getItem('karaoke1')),
            isLoading:true
          })
      sessionStorage.getItem('other') && this.setState({
            other: JSON.parse(sessionStorage.getItem('other')),
            isLoading:true
          })
    }
    else{
      this.fetchCategories();
    }

  }

  componentWillUpdate(nextProps, nextState) {
    sessionStorage.setItem('karaoke1', JSON.stringify(nextState.karaoke1));
    sessionStorage.setItem('other', JSON.stringify(nextState.other));
    //sessionStorage.setItem('geetDate', Date.now());
  }

  componentWillUnmount = () => {
   this.Unmounted = true
}

  render() {
    
    const{karaoke1,other, isLoading} = this.state;
    //console.log("object1", karaoke)
    {/**Category Movies */}
    const getKaraoke = karaoke1.map(d =>
      <div className="textChange" style={{width: '100%'}}>
      <h3 className="text-uppercase">{d.name}</h3>
            <div className="row">
            {d.karaokes.map(a=>
              <div className="col-lg-2 col-md-2 col-sm-3 col-4">
              <div className="geetInfo1 link" >
               <img onClick={ () => this.handleShow()} src={a.logo} height='200px' /> 
                <div className="truncate cardContent" style={{textAlign:'center'}} ><p >{a.name}</p></div>
              </div>
              </div> 
              )}
              </div>
              <hr/>
          </div>
          )
          {/**Vendor */}
                const getKaraoke2= other.map(a =>
                <div className="textChange" style={{width: '100%'}}>
                <h3 className="text-uppercase">{a.name}</h3>
                <div className = "row">
                 {a.karaokes.map(d =>   
                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                      <div className="geetInfo1 link">
                      
                          <img onClick={ () => this.handleShow()} src={d.logo} height='200px' /> 
                          <div className="truncate cardContent" style={{textAlign:'center'}}><p >{d.name}</p></div></div>
                          </div>    
                      )}
                      </div>
                      <hr/>
                      </div>
                )
    // Modal Starts
    if(this.state.show){
      return (
        <div className="modal ">
        <Modal
         show={this.state.show} onHide={()=>this.handleClose()}
         centered
         
        >
        <Modal.Header closeButton >
        <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
        <div className = "row">
        <div className="col-4">
        </div>
        <div className="col-4">
        <div className = "row">
        <div className="col-6">
        <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
        <img src = {playstore} /> 
        </a>
        </div>
        <div className="col-6">
        <img src = {appstore}/>
        </div>
        </div>
        </div>
        <div className="col-4">
        </div>
        </div>
        <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
        <p style={{textAlign:'center'}}>Scan QR Code</p>
        <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
        <div className="row">
        <div className="col-2"><img className="modalqr" src = {logo} style={{height:'100px', width:'140px'}}/>
        </div>
        <div className="col-2">
        </div>
        <div className="col-2">
        </div>
        </div>
        </div>
        <div className="col-4"></div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={()=>this.handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
    )
    }
    //Modal Ends

    if(isLoading){
    return (
      <React.Fragment>
      <Grid container spacing={4} className="homepageGridContainer">
              {/**Category*/}
              {getKaraoke}
         </Grid>     
         <Grid container spacing={4} className="homepageGridContainer">
              {/** Vendors*/}
              {getKaraoke2}
              </Grid>
             
       </React.Fragment>
    );
    }
    else return <Loader1/>
  }
}

export default Karaoke;
