import { getBaseRequestConfig } from "../baseRequestConfig";
import asyncFetch from "../async-fetch";

export async function ResetPasswordApi(
  email,
  token,
  password,
  password_conformation
) {
  // console.log(email, token, password_conformation, password);
  const url = process.env.REACT_APP_API_URL + "/user/reset-password";

  const baseRequestConfig = getBaseRequestConfig();

  const requestConfig = Object.assign({}, baseRequestConfig, {
    method: "POST",
    data: {
      email: email,
      token: token,
      password: password,
      password_confirmation: password_conformation
    }
  });

  const response = await asyncFetch(url, requestConfig);

  return await response;
}
