import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./dropdown.scss";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "transparent"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const Dropdown = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel className="seeMoreBtn">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="seeMore"
        >
          {/* <Typography className={classes.heading}>See More</Typography> */}
          <Typography variant="button">See More</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="details">
          <Typography variant="body1" gutterBottom>
            {props.description}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Category: {props.selectedVideoCategory}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default Dropdown;
