import React, { Component } from "react";
import axios from 'axios';
import ListItem from "@material-ui/core/ListItem";
import { View } from 'react-native';
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import "../../styles/videos.css";
import "../../styles/common.css";
import Grid from "@material-ui/core/Grid";
import Loader1 from "../loader/loader";
import {Button, Modal} from 'react-bootstrap';
import playstore from '../../assets/google-play-store-10.jpg';
import appstore from '../../assets/app-store-icon.png';
import logo from '../../assets/logo.png';
import playlist from '../../assets/playlist.png';
import Typography from "@material-ui/core/Typography";
import audio_placeholder from "../../assets/default_placeholders/default_audio.jpg";

class Geet extends Component {
  constructor(props){
    super(props);
    this.state= {
      geet:[],
      isLoading:false,
      
    }
  }

  fetchCategories =()=>{
    this.setState({
      isLoading:false,
      geet:[]
    })

    axios.get("https://mnm.nettvnepal.com.np/api/net-tv/audios/home")
    .then(res => { if(!this.unMounted){
                     this.setState({
                         geet:res.data.data,
                         isLoading:true
                 })
             }
                     })
         .catch((error) =>{
            //  console.log(error);
         }) 
  }

  componentDidMount = () =>{
    
    // const date = sessionStorage.getItem('geetDate');
    // const geetDate = date && new Date(parseInt(date));
    // const now = new Date;

    // const dataAge = Math.round((now - geetDate)/(1000*60)); // in minutes
    // const tooOld = dataAge >=5;
    if(sessionStorage.getItem('geet')){
      // console.log("using data from session storage");
      sessionStorage.getItem('geet') && this.setState({
            geet: JSON.parse(sessionStorage.getItem('geet')),
            isLoading:true
          })
    }
    else{
      this.fetchCategories();
    }

    // if(tooOld){
    //   this.fetchCategories()
    // }
    // else{
    //   console.log(`Using data from localstorage that are ${dataAge} minutes old.`)
    //   localStorage.getItem('geet') && this.setState({
    //     geet: JSON.parse(localStorage.getItem('geet')),
    //     isLoading:true
    //   })

    // }

  }

  componentWillUpdate(nextProps, nextState) {
    sessionStorage.setItem('geet', JSON.stringify(nextState.geet));
    sessionStorage.setItem('geetDate', Date.now());
  }
  

  handleShow = () => {
    this.setState({
        show:true
    })

  }
  handleClose = () =>{
      this.setState({
        show:false
      })
  }


  componentWillUnmount = () => {
   this.Unmounted = true
}

  render() {
    const{geet, isLoading} = this.state;
    // console.log("geet", geet)
    {/**Category Movies */}
    const getMusic = geet.map(d =>
      d.name !== "Banners"?
      <div>
      <View style={{flexDirection:"row"}}>
      <View style={{flex:1}}>
      <div className="textChange text-uppercase" style={{justifyContent:'flex-start', fontSize:'1.6rem', fontWeight:'bold', marginBottom:'10px'}} >
        {/* {d.name} */}
        {d.name === "Banners" ?
          "Featured"
          :
          d.name
        }
      </div>
      </View>
      <div className = "view" style={{float:"right"}}>
      {/* {[
        "View All",
      ].map((text, index) => (
        <div>
        <a tabIndex="0"> 
        <ListItem button key={text + index} component={Link} to={d.name}>
          <ListItemText primary={text} />
        </ListItem>
        </a>
        </div>
      ))} */}
      </div>
      </View>
            <div className="row">
            {d.name === "POPULAR" || d.name === "FEATURED ALBUMS"?
              d.items.map(a=>
                <div className=" col-lg-2 col-md-2 col-sm-3 col-4 ">
                  <div className="geetInfo1">
                  <Link to={{
                    pathname: "/detail/"+a.slug,
                    state:{ 
                      data: a}// your data array of objects
                  }}
                  style={{textDecoration:'none'}}
                  >
                  <div className="imageDiv1">
                    {a.logo === "https://mnm.nettvnepal.com.np/images/playlist/default.png" ? <img src='https://mnm.nettvnepal.com.np/images/album/default.jpg' style={{ height:'195px'}} alt = "geetImage" />: 
                    <img onClick={ () => this.handleShow()}
                    style={{height:'100%', width:'100%'}}
                    src={
                     a.logo
                    } alt = "geetImage"

                    onError={
                      e => {
                        e.target.src = audio_placeholder
                      }
                    }
                  />}
                  {a.type === "album" ||
                  a.type === "playlist" ? (
                    <div className="playlistInThumbnail">
                    <img src = {playlist} style={{width:'35%'}} alt = "geetImage"
                      onError={
                        e => {
                          e.target.src = audio_placeholder
                        }
                      }
                    />
                      <Typography variant="h6" gutterBottom>
                        {a.count}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                  </div>
                    <div className ="textChange truncate cardContent" style={{ padding: "0px 5px;" }} >
                      {a.title}
                    </div>
                    </Link>
                  </div>
                  
                </div> 
              )
              :d.items.map(a=>
                <div className="col-lg-2 col-md-2 col-sm-3 col-4 link">
                  <div className="geetInfo1">
                    {a.logo === "https://mnm.nettvnepal.com.np/images/playlist/default.png" ? <img src='https://mnm.nettvnepal.com.np/images/album/default.jpg' style={{ height:'195px'}} alt="geetImage"/>: 
                    <img onClick={ () => this.handleShow()}
                    style={{height:'100%', width:'100%'}}
                    src={
                     a.logo
                    } alt = "geetImage"
                    onError={
                      e => {
                        e.target.src = audio_placeholder
                      }
                    }
                  />}
                    <div className ="textChange truncate cardContent" style={{ padding: "0px 5px;" }} >
                      {a.title}
                    </div>
                  </div>
                  
                </div> 
              )
            }
            </div>
            <hr/>
      </div>
      :""
      )

      // Modal Starts
      if(this.state.show){
        return (
          <div className="modal ">
          <Modal
           show={this.state.show} onHide={()=>this.handleClose()}
           centered
           
          >
          <Modal.Header closeButton >
          <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
          <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
          <div className = "row">
          <div className="col-4">
          </div>
          <div className="col-4">
          <div className = "row">
          <div className="col-6">
          <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
          <img src = {playstore} /> 
          </a>
          </div>
          <div className="col-6">
          <img src = {appstore}/>
          </div>
          </div>
          </div>
          <div className="col-4">
          </div>
          </div>
          <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
          <p style={{textAlign:'center'}}>Scan QR Code</p>
          <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
          <div className="row">
          <div className="col-2 "><img className="modalqr" src = {logo} style={{height:'100px', width:'140px'}}/>
          </div>
          <div className="col-2">
          </div>
          <div className="col-2">
          </div>
          </div>
          </div>
          <div className="col-4"></div>
          </div>
        </Modal.Body>
  
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>this.handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
      )
      }
      //Modal Ends
    if(isLoading){
    return (
      <React.Fragment>
      <Grid container spacing={4} className="homepageGridContainer">
              {/**Category*/}
              {getMusic}
      </Grid>
             
       </React.Fragment>
    );
    }
    else return <Loader1/>
  }
}

export default Geet;
