import { getBaseRequestConfig } from "../baseRequestConfig";
import asyncFetch from "../async-fetch";

export async function ViewCount(id) {
  const url = process.env.REACT_APP_API_URL + "/videos/" + id + "/view";

  const baseRequestConfig = getBaseRequestConfig();

  const requestConfig = Object.assign({}, baseRequestConfig, {
    method: "POST"
  });

  const response = await asyncFetch(url, requestConfig);

  return await response;
}
