import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import UserProfile from "../components/user/userProfile";
import ContactUs from "../components/ContactUs/contactUs";
import SearchResult from "../components/searchResult";
import Videos from "../components/videos/videos";
import Login from "../components/login/login";
import Channel from "../components/channels/channel";
import { BrowserRouter as Router, Route, withRouter, Switch} from "react-router-dom";
import UserPhoto from "../components/user/userPhoto";
import QrLogin from "../components/login/qrlogin";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import PackagesList from "../components/user/packagesList";
import Search from "../components/search";
import PlayerPage from "./playerPage";
import Fonepay from "../components/user/fonpay";
import EsewaSuccess from "../components/user/esewaSuccess";
import EsewaFailure from "../components/user/esewaFailure";
import { Link } from "react-router-dom";
import "../styles/navbar.css";
import Button from "@material-ui/core/Button";
import ResetPassword from "../components/login/resetPassword";
//import SearchResultValues from "../components/searchResultValues";
import Terms from "../components/Setting/terms";
import Privacy from "../components/Setting/privacy";
import About from "../components/Setting/about";
import News from "../components/channels/news";
import musicVideos from "../components/musicVideos/musicVideos";
import Movies from "../components/channels/movies";
import Karaoke from "../components/channels/karaoke";
import Geet from "../components/channels/geet";
import liveTV from "../components/channels/liveTV";
import tvShows from "../components/channels/tvShows";
import FM from "../components/fm";
import TrendingPagination from "../components/pagination/trendingPagination";
import FeaturedAPagination from "../components/pagination/featuredAPagination";
import TopCharts from "../components/pagination/topCharts";
import Popular from "../components/pagination/popular";
import DetailHome from "../components/videos/detailHome";
import DetailMovie from "../components/videos/detailMovie";
import DetailHomeMovie from "../components/videos/detailHomeMovie";
import featuredGeet from "../components/videos/homePagination/featuredGeet";
import featuredMovies from "../components/videos/homePagination/featuredMovies";
import featuredMusicVideo from "../components/videos/homePagination/featuredMusicVideo";
import featuredKaraokes from "../components/videos/homePagination/featuredKaraokes";
import featuredRad from "../components/videos/homePagination/featuredRad";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    // backgroundColor: theme.palette.secondary[700],
    backgroundColor: "white",
    color: "rgba(0,0,0,0.8)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  botNavImg: {
    width: "35%"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    // marginRight: 36,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      minHeight: "60px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      minHeight: "50px"
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: "whitesmoke",
    overflowX: "hidden",
    padding: theme.spacing(1),
    // width: "90%",
    [theme.breakpoints.down("sm")]: {
      padding: "0px"
    }
  },

  sectionDesktop: {
    marginLeft: "auto",
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "flex"
    },

  },
  morefromchautari: {
    textAlign: "center",
    color: "black!important"
  },
  link: {
    textDecoration: "none"
    // color: fade(theme.palette.common.white, 0.75)
  },
  iconAlign: {
    paddingLeft: 8,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  botNav: {
    // backgroundColor: theme.palette.grey[800],
    // backgroundColor: theme.palette.secondary[700],
    backgroundColor: "white",
    boxShadhow: "0px 1px 6px 2px rgba(0,0,0,0.5)",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
      position: "fixed",
      bottom: 0,
      width: "100%",
      zIndex: 1
    }
  },
  more: {
    color: "black"
  }
}));

export default function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-search-account-menu";
  const [anchorEll, setAnchorEll] = React.useState(null);

  const menuIcons = [
    require("../assets/home.png"),
    require("../assets/songs.png"),
    require("../assets/movies.png"),
    require("../assets/music_videos.png")
  ];
  const menuMoreIcons = [
    require("../assets/kaorake_songs.png"),
    require("../assets/fm_radio.png"),
    require("../assets/live_tv.png"),
    require("../assets/tv_shows.png"),
    require("../assets/online_news.png"),
    require("../assets/contact.png"),
    require("../assets/e_learning.png"),
    require("../assets/privacy_policy.png"),
    require("../assets/about_us.png"),
    require("../assets/packages.png")
  ];

  function handleDrawerOpen() {
    setOpen(true);
    // document.getElementById("moreFromChautari").innerHTML =
    //   "More From Chautari";
  }

  function handleDrawerClose() {
    setOpen(false);
    document.getElementById("moreFromChautari").innerHTML = "More";
  }

  function handleClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
    setAnchorEll(null);
  }

  function handleMobileMenuClose() {
    // setMobileMoreAnchorEl(null);
  }

  function handleClickBotMore(event) {
    setAnchorEll(event.currentTarget);
  }

  return (
    
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="default"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open
              })}
            >
              <MenuIcon />
            </IconButton>
            <div className="navbarLogoDiv">
              <Link to="/" color="inherit" style={{ cursor: "pointer" }}>
                <img
                  src={require("../assets/LogoRed.png")}
                  alt="video chautari logo"
                  className="mainlogo"
                />
              </Link>
            </div>

            <Search />

            

            <div className={classes.sectionDesktop}>
              {/* <a href="https://voting.momoappnepal.com/" target="_blank"><Button variant="contained" className="momoOrangeBtn" style={{backgroundColor: "#0056b3", fontSize: "13.5px"}}>SaReGaMaPa Li'l Champs Voting</Button></a> */}
              { (localStorage.getItem("helloToken1")|| localStorage.getItem("helloToken")) ? 
                <Link to="/user" className="navPP">

                  <UserPhoto />
                </Link> : 
                  <Link to="/login" className="navLogin">
                  <Button variant="contained" className="momoOrangeBtn">Login</Button>
                </Link>
              
              }

              <Popover
                className="account"
                aria-haspopup="true"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={handleClose}
              >
                <Typography>
                  {localStorage.getItem("currentUser") === "null" ? (
                    ""
                  ) : (
                    <Link to="/user" className={classes.link} color="inherit">
                      <MenuItem onClick={handleClose}>Profile</MenuItem>
                    </Link>
                  )}

                  {localStorage.getItem("currentUser") === "null" ? (
                    ""
                  ) : (
                    <Link
                      to="/dashboard"
                      className={classes.link}
                      color="inherit"
                    >
                      <MenuItem onClick={handleClose}>Dashboard</MenuItem>
                    </Link>
                  )}

                  <Button
                    variant="contained"
                    color="default"
                    onClick={props.toggleTheme}
                  >
                    Toggle Theme Type
                  </Button>
                </Typography>
              </Popover>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <React.Fragment>
                  <ChevronRightIcon />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ChevronLeftIcon />
                </React.Fragment>
              )}
            </IconButton>
          </div>
          <Divider className="sideMenuHr" />
          <List>
            {["Home", "Geet", "Movies", "Music Videos"].map((text, index) => (
            <div className="button">
            <a tabIndex="1">  <ListItem
                button
                key={text + index}
                component={Link}
                to={`/${text}`}
              >
                <ListItemIcon className={classes.iconAlign}>
                  <img src={menuIcons[index]} />
                </ListItemIcon>
                <ListItemText primary={text} className="sideMenuText" />
              </ListItem>
              </a>
              </div>
            ))}
          </List>
          <Divider className="sideMenuHr" />
          <List>
            <Typography
              variant="h6"
              id="moreFromChautari"
              className={classes.morefromchautari}
            >
              More
            </Typography>
            {[
              "Karaoke Songs",
              "FM-Radio",
             // "Live TV",
             // "TV Shows",
             // "Online News",
              "Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
              "About Us",
              "Subscriptions"
            ].map((text, index) => (
              <div className = "button">
              <a tabIndex="1"> 
              <ListItem button key={text + index} component={Link} to={text}>
                <ListItemIcon className={classes.iconAlign}>
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  {/* <i className="material-icons">{menuMoreIcons[index]}</i> */}
                  <img src={menuMoreIcons[index]} />
                </ListItemIcon>
                <ListItemText primary={text} className="sideMenuText" />
              </ListItem>
              </a>
              </div>
            ))}
          </List>
          <h5 className="copyright">© Copyright {new Date().getFullYear()}, New It Venture</h5>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* Components are called here */}
          
          <div>
            <Switch>
            <Route exact path="/" component={Videos} />
            <Route path="/home" component={Videos} />
           {/** <Route path ="/online news" component={News}/>
            <Route path = "/tv shows" component = {tvShows}/> */}
            <Route path = "/geet" component= {Geet}/>
            <Route path = "/live tv" component = {liveTV}/>
            
            <Route path = "/music videos" component={musicVideos}/>
            <Route path = "/movies" component={Movies}/>
            <Route path = "/karaoke songs" component = {Karaoke}/>
            <Route path = "/fm-radio" component = {FM}/>
            <Route path = "/trending" component = {TrendingPagination}/>
            <Route path = "/featured artists" component = {FeaturedAPagination}/>
            <Route path = "/featured geet" component = {featuredGeet} />
            <Route path = "/featured movies" component = {featuredMovies} />
            <Route path = "/featured music video" component = {featuredMusicVideo} />
            <Route path = "/featured karaokes" component = {featuredKaraokes} />
            <Route path = "/featured radios" component = {featuredRad} />
            <Route path = "/top charts" component = {TopCharts}/>
            <Route path = "/popular" component = {Popular}/>
            <Route path = "/featured albums" component = {Geet}/>
            <Route path = "/qrLogin" component = {QrLogin} />
            <Route path = "/Contact Us" component = {ContactUs} />
            {/* <PrivateRoute path="/" component={Videos} /> */}
            <Route path="/searchResult/:fooId" component={SearchResult} />
            {/* <PrivateRoute path="/Channel" component={Channel} /> */}
            <Route path="/player/:id" component={PlayerPage} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path = "/fonepay" component={Fonepay} />
            
            <Route path="/terms & conditions" component={Terms} />
            <Route path="/privacy policy" component={Privacy} />
            <Route path="/about us" component={About} />
            <Route path="/Subscriptions" component={PackagesList} /> 
            <Route path="/esewaSuccess" component={EsewaSuccess} />
            <Route path="/esewaFailure" component={EsewaFailure} /> 
            <Route exact path="/detail/:slug" component={DetailHome}/> 
            <Route path="/movie/:name" component={DetailMovie}/>
            <Route path="/nepali/:title" component={DetailHomeMovie}/>
            <Route path="/login" component={Login} />
            <Route path="/channel" component={Channel} />
            <Route path="/user" component={UserProfile} />
            </Switch>

          </div>
        </main>
      </div>

      {/* Bottom NavBar */}
      <div className={classes.botNav}>
        {["Home", "Geet", "Movies", "Music Videos"].map((text, index) => (
          
          <div className="button">
          <a tabIndex="1"> 
          <Button
            className={classes.button}
            key={text + index}
            component={Link}
            to={`/${text}`}
          >
            {/* <i className="material-icons">{menuIcons[index]}</i> */}
            <img src={menuIcons[index]} className={classes.botNavImg} />
          </Button>
          </a>
          </div>
        ))}
        <Button
          aria-controls="simple-menu"
          aria-describedby="simple-menu"
          aria-haspopup="true"
          aria-owns={open ? "menu-list-grow" : undefined}
          onClick={handleClickBotMore}
        >
          <MenuIcon className={classes.more} />
        </Button>
        <Popover
          id="simple-menu"
          anchorEl={anchorEll}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          keepMounted
          open={Boolean(anchorEll)}
          onClose={handleClose}
          disablePortal
          className="botMore"
        >
          {[
            "Karaoke Songs",
            "FM/Radio",
            //"Live TV",
           // "TV Shows",
           // "Online News",
            "Subscription",
            "Rate Us",
            "Contact Us",
            "Terms & Conditions",
            "Privacy Policy",
            "About Us"
          ].map((text, index) => (
            <div className="button">
              <a tabIndex="1"> 
                <ListItem button key={text + index} component={Link} to={text}>
                  <ListItemIcon className={classes.iconAlign}>
                    <img src={menuMoreIcons[index]} />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </a>
            </div>
          ))}
        </Popover>
      </div>
    </React.Fragment>
  );
}
