import React from "react";
import PlayerLayout from "../components/player/playerLayout";

export default function PlayerPage({ match, location, history }) {
  // console.log(match, location, history);
  const {
    params: { id }
  } = match;

  return <PlayerLayout player={id} />;
}
