import React, { useState } from "react";
import "./App.css";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import lightBlue from "@material-ui/core/colors/lightBlue";
import red from "@material-ui/core/colors/red";
import { BrowserRouter as Router } from "react-router-dom";

import { StylesProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import UserInfo from "./components/userInfo";
import {Route} from 'react-router-dom';
import {Offline, Online} from 'react-detect-offline';

require("dotenv").config();

const App = () => {
  const [customTheme, setTheme] = useState({
    palette: {
      primary: lightBlue,
      secondary: grey,
      error: red,
      contrastThreshold: 3,
      tonalOffset: 0.2,
      type: "dark"
    }
  });

  const toggleDarkTheme = () => {
    let newPaletteType = themes.palette.type === "light" ? "dark" : "light";
    setTheme({
      palette: {
        primary: lightBlue,
        secondary: grey,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
        type: newPaletteType
      }
    });
  };

  const themes = createMuiTheme(customTheme);

  return (
    <div className="online">
    <Online>
    <StylesProvider injectFirst>
      <ThemeProvider theme={themes}>
        <Router>
          <div>
            <UserInfo onToggleDark={toggleDarkTheme} />

            {/* <Navbar /> */}
          </div>
        </Router>

        {/* <Auth /> */}
        <ToastContainer
          transition={Flip}
          autoClose={4000}
          draggablePercent={60}
          closeOnClick={false}
          newestOnTop
          position={toast.POSITION.BOTTOM_RIGHT}
          //hideProgressBar={true}
          className="toast"
          bodyClassName="toast-body"
          progressClassName="toast-progress"
        />
      </ThemeProvider>
    </StylesProvider>
    </Online>
    <div className="offline">
    <Offline>
    <h3>No internet</h3>
    <p>Try:</p>
    <ul>
    <li>Checking the network cables, modem, and router</li>
    <li>Reconnecting to Wi-Fi</li>
    </ul>
    <p>ERR_INTERNET_DISCONNECTED</p>
    </Offline>
    </div>
    </div>
  );
};

export default App;
