import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Loader1 from '../loader/loader';
import "../../styles/videos.css";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Button, Modal} from 'react-bootstrap';
import playstore from '../../assets/google-play-store-10.jpg';
import appstore from '../../assets/app-store-icon.png';
import logo from '../../assets/logo.png';
import playlist from '../../assets/playlist.png';
import axios from 'axios';
import ListItem from "@material-ui/core/ListItem";
import { View } from 'react-native';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
class Videos extends Component {
  constructor() {
    super();
    this.state = {
      response: [],
      thumbnail: "",
      isLoading:false,
      test: "musicVideo"
    };
  }

  componentDidMount = () =>{

    if(sessionStorage.getItem('response')){
      // console.log("using data from session storage");
      sessionStorage.getItem('response') && this.setState({
            response: JSON.parse(sessionStorage.getItem('response')),
            isLoading:true
          })
    }
    else{
      this.fetchCategories();
    }

  }
  componentWillUpdate(nextProps, nextState) {
    sessionStorage.setItem('response', JSON.stringify(nextState.response));
  }

 fetchCategories = () =>{
  axios.get("https://mnm.nettvnepal.com.np/api/net-tv/home/mnm",{
    headers: {
      'guest-secret-key': 'secretGuest123456789'
    }
  })
  .then(res => { 
    // console.log("Api response data ", res);
          if(!this.unMounted){
                   this.setState({
                       response:res.data.data,
                       isLoading:true
               })
           }
                   })
       .catch((error) =>{
          //  console.log(error);
       }) 
}
componentWillUnmount = () => {
  this.Unmounted = true
}

  handleShow = () => {
    this.setState({
        show:true
    })

  }
  handleClose = () =>{
      this.setState({
        show:false
      })
  }

  render() {
   var settings = {  
    className: "slider variable-width",
    dots:true,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    touchMove: true,
    swipeToSlide: true,
    swipe: true,
    responsive: [

      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

   };

    const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 3
    }
  };

  const responsive1 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 2000, min: 800 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 800, min: 550 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1
    }
  };
   // Modal Starts
   if(this.state.show){
    return (
      <div className="modal ">
      <Modal
       show={this.state.show} onHide={()=>this.handleClose()}
       centered
       
      >
      <Modal.Header closeButton >
      <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
      <div className = "row">
      <div className="col-4">
      </div>
      <div className="col-4">
      <div className = "row">
      <div className="col-6">
      <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
      <img src = {playstore} alt = "playstore" /> 
      </a>
      </div>
      <div className="col-6">
      <img src = {appstore} alt="appstore" />
      </div>
      </div>
      </div>
      <div className="col-4">
      </div>
      </div>
      <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
      <p style={{textAlign:'center'}}>Scan QR Code</p>
      <div className="row">
      <div className="col-4"></div>
      <div className="col-4">
      <div className="row">
      <div className="col-2"><img className="modalqr" alt="qrcode" src = {logo} style={{height:'100px', width:'140px'}}/>
      </div>
      <div className="col-2">
      </div>
      <div className="col-2">
      </div>
      </div>
      </div>
      <div className="col-4"></div>
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={()=>this.handleClose()}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  </div>
  )
  }
  //Modal Ends


    if(this.state.isLoading){
    return (
      <React.Fragment>
  {/* {console.log("Response ", this.state.response)} */}
  {this.state.response.map((homepageItem, index) => (
    
    homepageItem?.items?.length > 0 && 
      <div>
        
        {index == 2 ? <div className="container-fluid" style={{paddingRight: "30px", marginBottom: "28px"}}> 
            {/* <a href="https://voting.momoappnepal.com/" target="_blank">
              <img
                  src={require("../../assets/saregamabanner.png")}
                  alt="saregamapa lil champs nepal"
                  className="mainlogo"
                  style = {{height: "150px"}}
                />
            </a> */}
          </div>: ""}
        
      <View style={{flexDirection:"row"}}>
      <View style={{flex:1}}>
      <div className="textChange text-uppercase" style={{justifyContent:'flex-start', fontSize:'1.6rem', marginLeft:'13px',fontWeight:'bold', marginBottom:'0px'}} >
        {/* {d.name} */}
        {homepageItem.name === "Featured banners" ?"":homepageItem.name}
      </div>
      </View>
      {homepageItem.name === "Featured banners" ? "": 
      <div className = "view" style={{float:"right", marginRight:'16px'}}>
      {[
        "View All",
      ].map((text, index) => (
        <div>
        <a tabIndex = "0"> 
        <ListItem button key={text + index} component={Link} to={{
        
          pathname: homepageItem.name,
          state:{ 
            data: homepageItem}// your data array of objects
        }}
        >
          <ListItemText primary={text} />
        </ListItem>
        </a>
        </div>
      ))}
      </div>
      }
      </View>

      {
        homepageItem.name === "Featured Movies"?
        <div className="container-fluid">
        <Carousel 
        swipeable={true}
        draggable={false}
        //showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        //autoPlay={this.props.deviceType !== "mobile" ? true : false}
        //autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        //transitionDuration={500}
        containerClass="carousel-container"
        //removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        >
        {homepageItem.items.map(e =>
          <div className="col-12 pl-0 pr-0">
          <Link to={{
            pathname: "/nepali/"+e.title,
            state:{  
              data: e}// your data array of objects
          }}
          style={{textDecoration:'none'}}
          >
          <div className="videoInfo1 " style={{marginBottom:15, marginRight:'15px', backgroundColor:'white', boxShadow:'0 0 5px -3px rgba(0, 0, 0, 0.75)'}}>
          <img alt={e.title} src = {e.background} style={{width:'100%', height: '136px'}}/>
          
          <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center', fontSize: '1rem', fontWeight:'400' }} >
          {e.title}
          {e.type}
          </div>
          </div>
          </Link>
          </div>
          )
        }  
        </Carousel>
      </div>  :""}
          {homepageItem.name === "Featured Music Video"?
          <div className = "container-fluid">
          <Carousel 
          swipeable={true}
          draggable={false}
          responsive={responsive}
          
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          //autoPlay={this.props.deviceType !== "mobile" ? true : false}
          //autoPlaySpeed={1000}
          keyBoardControl={true}
          //customTransition="all .5"
          //transitionDuration={500}
          //containerClass="carousel-container"
          //removeArrowOnDeviceType={["tablet", "mobile"]}
          //deviceType={this.props.deviceType}
          //dotListClass="custom-dot-list-style"
          //itemClass="carousel-item-padding-40-px"
          >
          {homepageItem.items.map(e =>
            <div className="col-12 pl-0 pr-0 link">
            <div className="videoInfo1 " style={{marginBottom:15, marginRight:'15px', backgroundColor:'white', boxShadow:'0 0 5px -3px rgba(0, 0, 0, 0.75)'}}>
            <img alt={e.title} onClick={ () => this.handleShow()} src = {e.logo}/>
            
            <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center', fontSize: '1rem', fontWeight:'400' }} >
            {e.title}
            {e.type}
            </div>
            </div>
            </div>
            )
          }  
          </Carousel>
        </div>  :""}
        
        {
          (homepageItem.name !== "Featured Music Video") ? (homepageItem.name !== "Featured banners")? (homepageItem.name !== "Featured Movies") ?
          <div className="container-fluid">
          <Carousel 
          swipeable={true}
          draggable={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          //autoPlay={this.props.deviceType !== "mobile" ? true : false}
          //autoPlaySpeed={1000}
          keyBoardControl={true}>
        
        {homepageItem.items.map(e =>
          <div className="col-12 pl-0 pr-0 link" >

          {homepageItem.name === 'Featured radios' ? 
            <Link to ={{
            pathname:"/fm-radio",
            query:e
            }}>
            <div className="videoInfo1 " style={{marginBottom:15, marginRight:'15px', backgroundColor:'white', boxShadow:'0 0 5px -3px rgba(0, 0, 0, 0.75)'}}>
            {/** {e.background?<img src = {e.background}/>:<img src = {e.logo}/>} */}
            {e.channel_logo?<img alt = {e.slug} src = {e.channel_logo} style={{width:'100%'}}/>
            :e.mediaArt?<img alt = {e.slug} src = {e.mediaArt} style={{width:'100%'}}/>:
           e.background?<img alt = {e.slug} src = {e.background} style={{width:'100%'}}/>:
           homepageItem.name === "Featured karaokes" ? <img alt = {e.slug} src = {e.logo } style ={{width:'100%'}} />:
            <Link 
            to={{
              pathname: "/detail/"+e.slug,
              state:{ 
                data: e}// your data array of objects
            }}
            style={{textDecoration:'none'}}
            >
           <div className="imageDiv1">
           <img alt = {e.slug} src = {e.logo } style ={{width:'100%'}} />
             {e.type === "album" ||
             e.type === "playlist" ? (
               <div className="playlistInThumbnail">
               <img alt = {e.slug} src = {playlist} style={{width:'50%'}} />
                 <Typography variant="h6" gutterBottom>
                   {e.count}
                 </Typography>
               </div>
             ) : (
               ""
             )}
         </div>
         </Link>
           }
            <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center'}} >
            {e.channel_name?e.channel_name:
             e.mediaTitle?e.mediaTitle:
             e.title?e.title:e.name}
             
            </div>
            <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center', color:'#e93e22', textTransform:'capitalize' }} >
            {e.type}
             
            </div>
            </div>
            </Link>
          :
          
          <div className="videoInfo1 " style={{marginBottom:15, marginRight:'15px', backgroundColor:'white', boxShadow:'0 0 5px -3px rgba(0, 0, 0, 0.75)'}}>
          {/** {e.background?<img src = {e.background}/>:<img src = {e.logo}/>} */}
          {e.channel_logo?<img alt = {e.slug} onClick={ () => this.handleShow()} src = {e.channel_logo} style={{width:'100%'}}/>
          :e.mediaArt?<img alt = {e.slug} onClick={ () => this.handleShow()} src = {e.mediaArt} style={{width:'100%'}}/>:
         e.background?<img alt = {e.slug} onClick={ () => this.handleShow()} src = {e.background} style={{width:'100%'}}/>:
         homepageItem.name === "Featured karaokes" ? <img alt = {e.slug} onClick={ () => this.handleShow()} src = {e.logo } style ={{width:'100%'}} />:
          <Link 
          to={{
            pathname: "/detail/"+e.slug,
            state:{ 
              data: e}// your data array of objects
          }}
          style={{textDecoration:'none'}}
          >
         <div className="imageDiv1">
         <img alt = {e.slug} onClick={ () => this.handleShow()} src = {e.logo } style ={{width:'100%'}} />
           {e.type === "album" ||
           e.type === "playlist" ? (
             <div className="playlistInThumbnail">
             <img alt = {e.slug} src = {playlist} style={{width:'50%'}} />
               <Typography variant="h6" gutterBottom>
                 {e.count}
               </Typography>
             </div>
           ) : (
             ""
           )}
       </div>
       </Link>
         }
          <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center'}} >
          {e.channel_name?e.channel_name:
           e.mediaTitle?e.mediaTitle:
           e.title?e.title:e.name}
           
          </div>
          <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center', color:'#e93e22', textTransform:'capitalize' }} >
          {e.type}
           
          </div>
          </div>
          }
          </div>
          )
        } 
      </Carousel>
        </div>:""
        :"":""
      }
      {
        homepageItem.name === "Featured banners"?
        <div className="container-fluid ">
        <Slider {...settings} style={{ paddingTop:'15px' }}
                      >
                      
        {homepageItem.items.map(e =>
          e.count !== 1 ?
          <div className="videoInfo1 " style={{marginBottom:15, marginRight:'15px', backgroundColor:'white', boxShadow:'0 0 5px -3px rgba(0, 0, 0, 0.75)'}}>
          <Link to={{
            pathname: "/detail/"+e.slug,
            state:{ 
              data: e}// your data array of objects
          }}
          style={{textDecoration:'none'}}
          >
              <div className="imageDiv">
                <img alt={e.slug} class = "imageWidth" onClick={ () => this.handleShow()} src = {e.background} style={{height:'300px', width:'100%'}} />
                  {e.type === "album" ||
                  e.type === "playlist" ? (
                    <div className="playlistInThumbnail">
                    <img alt={e.slug} src = {playlist} style={{width:'35%'}}/>
                      <Typography variant="h6" gutterBottom>
                        {e.count}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
              </div>
              
          <div style={{backgroundColor:'white', boxShadow:'0 0 5px -3px rgba(0, 0, 0, 0.75)'}}>
         <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center', fontSize: '1rem', fontWeight:'400'}} >
          {e.title}
          
          </div>
          <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center',fontSize: '1rem', fontWeight:'400', color:'#e93e22',textTransform:'capitalize'}} >
           {e.type}
       
          </div> 
          </div>
          </Link>
          </div>
                  : 
                  <div className="videoInfo1 " style={{marginBottom:15, marginRight:'15px', backgroundColor:'white', boxShadow:'0 0 5px -3px rgba(0, 0, 0, 0.75)'}}>
                  {e.type === "movie"? 
                    <Link to={{
                      pathname: "/nepali/"+e.title,
                      state:{ 
                        data: e}// your data array of objects
                    }}
                    style={{textDecoration:'none'}}
                    >
                    <div className="imageDiv">
                    <img alt={e.title} src = {e.background} style={{height:'300px', width:'100%'}} class="imageWidth"/>
                    </div>
                    <div style={{backgroundColor:'white', boxShadow:'0 0 5px -3px rgba(0, 0, 0, 0.75)'}}>
                    <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center', fontSize: '1rem', fontWeight:'400' }} >
                    {e.title}
                    
                    </div>
                    <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center',fontSize: '1rem', fontWeight:'400', color:'#e93e22', textTransform:'capitalize' }} >
                    {e.type}
                
                    </div>
                    </div> 
                    </Link> :
                      <div class="link" onClick={ () => this.handleShow()}>
                        <div className="imageDiv">
                        <img alt={e.title} src = {e.background} style={{height:'300px', width:'100%'}} class="imageWidth"/>
                        </div>
                        <div style={{backgroundColor:'white', boxShadow:'0 0 5px -3px rgba(0, 0, 0, 0.75)'}}>
                        <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center', fontSize: '1rem', fontWeight:'400' }} >
                        {e.title}
                        
                        </div>
                        <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center',fontSize: '1rem', fontWeight:'400', color:'#e93e22', textTransform:'capitalize' }} >
                        {e.type}
                    
                        </div>
                        </div> 
                      </div>
                  }
                  
            </div>
                  
                
          )
        }   </Slider>
      </div>
      
        :""
        
        
      }

      <hr/>
          </div>
          ))
  } </React.Fragment>
    )}
    else{
      return <Loader1/>
  }
  }
}

export default Videos;