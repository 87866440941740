import React, { Component } from "react";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';
import { Button } from "react-bootstrap";
import Login from "./login";
import Loader1 from "../loader/loader";
class QrLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            goToLogin: false,
            errorMessage: "",
            statusCheck:false,
            status:[],
            dataCheck:false,
            qrmessage:[]
        }
    }

    fetchToken= (a)=> {
        // console.log("url to fetch",a)
        if(localStorage.getItem("helloToken1")){
            let formdata = new FormData();
            formdata.append("device_id", a);
            return Axios ({
                url: "https://mnm.nettvnepal.com.np/api/net-tv/qr-code/register-device?device_id="+a,
                method: "POST",
                headers: {
                    authorization: "Bearer " + localStorage.getItem("helloToken1")
                },
                data: formdata
            }).then((result)=>{
                let responseJson = result;
                // console.log("result from qrLogin:", responseJson);
      
                if(responseJson){
                      this.setState({
                        statusCheck:true,
                        qrmessage: responseJson.data.message,
                        status: responseJson.status
                      })
                     }
                },
                err => {
                    this.setState({
                        errorMessage: err.response.data.error
                    })
                })
        }
        else if (localStorage.getItem("helloToken")) {
            let formdata = new FormData();
            formdata.append("device_id", a);
            return Axios ({
                url: "https://mnm.nettvnepal.com.np/api/net-tv/qr-code/register-device?device_id="+a,
                method: "POST",
                headers: {
                    authorization: "Bearer " + localStorage.getItem("helloToken")
                },
                data: formdata
            }).then((result)=>{
                let responseJson = result;
                // console.log("result from qrLogin:", responseJson);
      
                if(responseJson){
                      this.setState({
                        statusCheck:true,
                        qrmessage: responseJson.data.message,
                        status: responseJson.status
                        
                      })}
                },
                err => {
                    this.setState({
                        errorMessage: err.response.data.error
                    })
                }
                )
        }
       
         else {
            this.setState({
                goToLogin: true
            })
        }
        localStorage.setItem("deviceID", a )
        
    }
    

    componentDidMount = () =>{
        // console.log("url", this.props.location.search)
        const values = queryString.parse(this.props.location.search)
        this.fetchToken(values.deviceId);
        // console.log("Device ID", values.deviceId )
        localStorage.setItem("deviceID", values.deviceId )

    }

    handleClick = () =>{
        window.location.replace("/home")
    }

    render() {
        //const authResult = new URLSearchParams(window.location.search).getAll; 
        //const code = authResult.get('code')
        //const aut = queryString.parse(window.location.search);
        //console.log("url", window.location.search)
        // console.log("error", this.state.errorMessage)
        // console.log("gotoLogin", this.state.goToLogin)
        return (
            <React.Fragment>
                {this.state.goToLogin ?
                    <Redirect to = "/login" />
                    :
                    
                    //<Redirect to = "/home"/>
                    <div className="qrloginContainer">
                    <div className="message">
                    <Typography variant="h4" gutterBottom>

                    {this.state.status === 200 ? this.state.qrmessage === "Successfully registered"?"Successfully Registered":<Loader1/>:<h4 style={{marginBottom:'0px'}} >Invalid Device ID</h4>}
                    <br/>
                    <Button onClick={()=>this.handleClick()} ><h5 style={{marginBottom:'0px'}} > Go Back Home </h5></Button>
                        
                    </Typography>
                    </div>
                </div> 
                
                    
            }
            </React.Fragment>
        )
    }
}

export default QrLogin;

  
