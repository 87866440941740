import { getBaseRequestConfig } from "../baseRequestConfig";
import asyncFetch from "../async-fetch";
import Axios from "axios";

export async function CategoriesVideosList() {
  // const url = process.env.REACT_APP_API_URL + "/categories/videos";

  // const baseRequestConfig = getBaseRequestConfig();

  // const response = await asyncFetch(url, baseRequestConfig);

  // return await response;
  return Axios({
    url: "https://mnm.nettvnepal.com.np/api/net-tv/home/mnm",
    method: "GET",
    headers: {
      'guest-secret-key': 'secretGuest123456789'
    },
    params: {
      isWebRequest: "true"
    }
  }).then(
    res => {
      // console.log("I am the response at apihome ", res);
      return res;
    },
    err => {
      // console.log("sign up error");
      // console.log(err);
    }
  );
}

export async function ChannelsVideosList() {
  const url = process.env.REACT_APP_API_URL + "/channels/videos";

  const baseRequestConfig = getBaseRequestConfig();

  const response = await asyncFetch(url, baseRequestConfig);

  return await response;
}
