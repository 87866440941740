import React, { Component } from "react";
import "../../styles/oneVideoList.scss";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import More from "../../common/more";
import { ViewCount } from "../../connectivity/home/api.increaseViewCount";

class OneVideoInList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      views_count: "",
      title: "",
      background: "",
      id: ""
    };
  }

  componentDidMount() {
    this.setState({
      views_count: this.props.category.view_count,
      title: this.props.category.title,
      background: this.props.category.background,
      id: this.props.category.id
    });
  }

  sideVideoClicked = () => {
    // localStorage.setItem("ClickedVideoID", this.props.category.id);
    window.location.reload();

    ViewCount(this.state.id);
  };

  render() {
    return (
      <div className="oneVideoListingWrapper">
        <div onClick={this.sideVideoClicked}>
          <Link to={`/player/${this.props.category.id}`}>
            <img src={this.state.background} alt={this.state.title} />
          </Link>

          <div className="views" hidden={this.state.views_count === 0}>
            <i className="material-icons">remove_red_eye</i>
            <Typography variant="caption" display="block">
              {this.state.views_count}
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="h6" gutterBottom>
            <Link to={`/player/${this.props.category.id}`}>
              {this.props.category.title}
            </Link>
          </Typography>
          <Typography variant="caption" display="block">
            publisher_name
          </Typography>
        </div>
        <More />
      </div>
    );
  }
}

export default OneVideoInList;
