import React, {Component} from 'react';
import RiseLoader from "react-spinners/RiseLoader";



class Loader1 extends React.Component{
    render(){
        return(
            <div className = "actLoader" style={{textAlign:'center', height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <RiseLoader
                    size={15}
                    color={"black"}
                />
            </div>
        )
    }
}
export default Loader1;
