import React, { Component } from "react";
import axios from 'axios';
import ReactAudioPlayer from 'react-audio-player';
import '../styles/fm.css';
import Loader1 from "./loader/loader";
import "../styles/common.css";
import audio_placeholder from "../assets/default_placeholders/default_audio.jpg";

class FM extends Component {
  constructor(props){
    super(props);
    this.state= {
      fm:[],
      a:[],
      isLoading:false,
      imgLoaded:false,
      play:false,
      pause:false
      
    }
  }

  fetchCategories =()=>{
    axios.get("https://mnm.nettvnepal.com.np/api/v2/fm/list")
    .then(res => { if(!this.unMounted){
                     this.setState({
                         fm:res.data.data,
                         isLoading:true
                 })
             }
                     })
         .catch((error) =>{
            //  console.log(error);
         }) 
  }

  componentDidMount = () =>{
    this.fetchCategories();
    if(this.props.location?.query){
      this.setState({
          a:this.props.location?.query,
          imgLoaded:true,
          play: true
      });
  }

  }

  handleClick = (data) =>{
    this.setState({
      a:data,
      imgLoaded:true,
      
    })
  }

  handlePLay =()=>{
    this.setState({
      play:true
    })

  }

  componentWillUnmount = () => {
   this.Unmounted = true
}
//style = {{boxShadow: '3px 3px 5px 6px #ccc'}}
  render() {
    const{a, imgLoaded} = this.state;
    //console.log(a.mediaArt)
    const{fm, isLoading} = this.state;
    //console.log("object", fm);
    if(isLoading){
    return (
      <React.Fragment>
              <div className="textChange row py-3 px-3" style={{width:'100%'}}>
              <div className = "mBottom col-md-9 col-sm-12 col-xs-12">
              <div className = "row">
              {fm.map(a => 
                <div className="col-md-3 col-xs-3">
                <img class="link" onClick={ () => this.handleClick(a)} src={a.mediaArt} style={{ height:'195px'}} />
                <div className="truncate link" style={{textAlign:'center'}}><p>{a.mediaTitle}</p></div>
                </div>
                )}
              </div> 
              </div>
              <div className = "fmChange col-md-3 col-xs-3">
              {imgLoaded? 
                <div className = "audioFixed">
                <h3 style={{textAlign:'center', marginTop:'20px'}}>{a.mediaTitle} {this.state.play}</h3>
               <img src = {a.mediaArt} 
                onError={
                      e => {
                        e.target.src = audio_placeholder
                      }
                    }
              />
              { this.state.play? 
                <img style={{height:'163px', marginBottom:'10px'}} src = {require("../assets/gif.gif")}
                onError={
                  e => {
                    e.target.src = audio_placeholder
                  }
                }
                />:
                <img style={{height:'163px', marginBottom:'10px'}} src = {require("../assets/audio.jpg")}
                onError={
                  e => {
                    e.target.src = audio_placeholder
                  }
                }
                />}
               <div className = "audioCenter" style = {{justifyContent:'center', marginBottom:'10px'}}>
               <ReactAudioPlayer
                    src={a.mediaUrl}
                    onPlay={e => this.setState({
                      play:true
                    })}
                    onPause={e => this.setState({
                      play:false
                    })}
                    autoPlay
                    controls/>
                    </div>
                  </div>
                :<div className = "audioFixed">
               <h3 style={{textAlign:'center', marginTop:'25px', marginBottom:'0px'}}>Radio Kantipur</h3>
               <img src = "https://mnm.nettvnepal.com.np/images/fm/4PZ0bjgz83wgMwjgojcKg9DiON1anZSf9RC2wuYi.jpeg"
                onError={
                  e => {
                    e.target.src = audio_placeholder
                  }
                }
               />
               {this.state.play? 
               <img style={{height:'163px', marginBottom:'10px'}} src = {require("../assets/gif.gif")}
                onError={
                  e => {
                    e.target.src = audio_placeholder
                  }
                }
               />:
               <img style={{height:'163px', marginBottom:'10px'}} src = {require("../assets/audio.jpg")}
                onError={
                  e => {
                    e.target.src = audio_placeholder
                  }
                }
               />}
               
              <div className = "audioCenter">
               <ReactAudioPlayer
                    src="http://broadcast.radiokantipur.com:7248/stream"
                    onPlay={e => this.setState({
                      play:true
                    })}
                    onPause={e => this.setState({
                      play:false
                    })}
                    autoPlay
                    controls/>
                    </div>
                    </div>
              }
              </div>
              </div>
       </React.Fragment>
    );
    }
    else return <Loader1/>
  }
}

export default FM;
