import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
// import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoginForm from "../login/loginForm";
// import FormControl from '@material-ui/core/FormControl';
// import { toast } from "react-toastify";
// import Axios from "axios";

export class AddComment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      displayModal: false
    };
  }
  handleChange = e => {
    e.preventDefault();
    this.props.getComments(e.target.comment.value);
    this.setState({ isSubmitting: true });
    document.getElementById("commentForm").reset();
  };

  commentClicked = e => {
    // console.log("Comment Clicked", e);
    if (localStorage.getItem("currentUser") === "null") {
      this.setState({
        displayModal: true
      });
    }
  };

  modalClose = () => {
    this.setState({
      displayModal: false
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className={!this.state.displayModal ? "modal hide" : "modal show"}>
          <div className="loginModal">
            <div style={{ textAlign: "right" }}>
              <i
                className="material-icons"
                style={{ cursor: "pointer" }}
                onClick={this.modalClose}
              >
                close
              </i>
            </div>
            <LoginForm directTo="samePage" />
          </div>
        </div>
        <form
          onSubmit={this.handleChange.bind(this)}
          className="commentField"
          id={`commentForm`}
        >
          <TextField
            type="text"
            label="Add Comments"
            name="comment"
            //value={comment}
            onClick={this.commentClicked}
            fullWidth
            required
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    variant="contained"
                    color="inherit"
                    type="submit"
                    size="small"
                  >
                    {this.props.isSubmitting ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      <Icon fontSize="small">send</Icon>
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </form>
      </React.Fragment>
    );
  }
}
