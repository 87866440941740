import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "../../styles/login.scss";

class SignUpMessage extends Component {
  state = {};
  render() {
    return (
      <div id="signUpWrapper">
        <div className="askSignUp">
          <Typography variant="subtitle1">DON'T HAVE AN ACCOUNT?</Typography>
          <Typography variant="h4" gutterBottom>
            SIGN UP
          </Typography>
        </div>
        <Typography variant="body1" gutterBottom className="signUpMessage">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.Lorem ipsum dolor sit
          amet, consectetur adipisicing elit. Quos blanditiis tenetur unde
          suscipit, quam beatae rerum inventore consectetur, neque doloribus
        </Typography>
        <div>
          <Button
            variant="contained"
            color="secondary"
            className="loginBtn"
            onClick={this.props.signUpBtnClicked}
          >
            Sign Up
          </Button>
        </div>
      </div>
    );
  }
}

export default SignUpMessage;
