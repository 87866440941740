import React, { useState } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { toast } from "react-toastify";

const token = localStorage.getItem("currentUser");

const AuthToken = {
  isAuthenticated: false,

  authenticate(cb) {
    token ? (this.isAuthenticated = true) : (this.isAuthenticated = false);
    // console.log(cb);
    // console.log(this.isAuthenticated);
    setTimeout(cb, 100);
  },
  signout(cb) {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("state");
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

export default function Auth(props) {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  const login = () => {
    AuthToken.authenticate(() => {
      setRedirectToReferrer(true);
    });
  };
  const handleChange = () => {
    // console.log("changes");
  };

  const { from } = props.location.state || { from: { pathname: "/" } };

  // console.log(props.location.state);
  if (redirectToReferrer === true) {
    return <Redirect to={from} />;
  }
  return (
    <div>
      {toast.error("You must log in first")}

      <form
        noValidate
        // autoComplete="off"
      >
        <Typography variant="h4">LOGIN</Typography>

        <TextField
          id="outlined-with-placeholder"
          label="Email or Phone Number"
          className="fields"
          margin="normal"
          name="email"
          type="email"
          variant="outlined"
          onChange={handleChange}
          noValidate
        />
        <TextField
          id="outlined-with-placeholder"
          label="Password"
          className="fields"
          type="password"
          autoComplete="current-password"
          margin="normal"
          name="password"
          variant="outlined"
          onChange={handleChange}
          noValidate
        />

        <div className="login">
          <Button
            variant="contained"
            color="secondary"
            className="loginBtn"
            onClick={login}
          >
            Log In
          </Button>
        </div>
      </form>
    </div>
  );
}

export const AuthButton = withRouter(({ history }) =>
  AuthToken.isAuthenticated === true ? (
    <p>
      Welcome!
      <button
        onClick={() => {
          AuthToken.signout(() => history.push("/"));
        }}
      >
        Sign Out
      </button>
    </p>
  ) : (
    <p> You are not logged in</p>
  )
);

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      AuthToken.isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/logins",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
