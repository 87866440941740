import React, { Component } from "react";
import { getUserInfo } from "../../connectivity/api.userInfo";
import "../../styles/userProfile.scss";
// import { red } from "@material-ui/core/colors";

class UserPhoto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userImage: "",
      noImage: false
    };
  }

  componentDidMount() {
    getUserInfo().then(res => {
      // console.log("user avatar", res.data.user_detail);

      // if (!!res.data.user_detail.avatar) {
      if (res.data.user_detail === null || !!res.data.user_detail.avatar) {
        this.setState({
          noImage: true
        });
      }

      if (this.props.photo === true) {
        this.setState({
          userImage: res.data.user_detail.avatar
        });
      } else if (res.data.user_detail === null) {
        this.setState({
          noImage: true
        });
      } else {
        this.setState({
          userImage: res.data.user_detail.avatar
        });
      }

      // if (res.data.user_detail === null) {
      //   this.setState({
      //     noImage: true
      //   });
      // } else {
      //   this.setState({
      //     userImage: res.data.user_detail.avatar
      //   });
      // }

      if (document.getElementsByClassName("userImageDiv")[1] !== undefined) {
        document.getElementsByClassName("userImageDiv")[1].style.height =
          document.getElementsByClassName("userImageDiv")[1].offsetWidth + "px";
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="userImageDiv">
          {!this.state.noImage ? (
            <img
              src={require("../../assets/default-profile.png")}
              alt="Default Profile"
            />
          ) : (
            <img src={this.state.userImage} alt="User Profile" />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default UserPhoto;
