import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

class GoBackToLogin extends Component {
  state = {};
  render() {
    return (
      <div id="goBackToLogin">
        <div className="askSignUp">
          <Typography variant="subtitle1">
            WHAT TO SHOW NOW THAT WE'RE IN
          </Typography>
          <Typography variant="h4" gutterBottom>
            THE SIGN UP FORM
          </Typography>
        </div>
        <Typography variant="body1" gutterBottom className="signUpMessage">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
          blanditiis tenetur unde suscipit, quam beatae rerum inventore
          consectetur, neque doloribus, cupiditate numquam dignissimos laborum
          fugiat deleniti? Eum quasi quidem quibusdam.Lorem ipsum dolor sit
          amet, consectetur adipisicing elit. Quos blanditiis tenetur unde
          suscipit, quam beatae rerum inventore consectetur, neque doloribus
        </Typography>
        <div>
          <Button
            variant="contained"
            color="secondary"
            className="loginBtn"
            onClick={this.props.signUpBtnClicked}
          >
            Go Back To Login
          </Button>
        </div>
      </div>
    );
  }
}

export default GoBackToLogin;
