 export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    // console.log(serializedState);

    if (serializedState === null) {
      return undefined;
    }

    // console.log(serializedState);

    return serializedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = state;
    localStorage.setItem("state", serializedState);
  } catch (err) {}
};
