import React from "react";
// import PropTypes from "prop-types";

import ReactJWPlayer from "react-jw-player";
// import Axios from "axios";
import uuid from "uuid";
// import { VideoById } from "../../connectivity/player/api.videoById";

const displayName = "ReactJWPlayerContainer";

const propTypes = {
  // playlist: PropTypes.string.isRequired,
  // playerScript: PropTypes.string.isRequired
};

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoTitle: "",
      videoUrl: ""
    };

    // console.log(this.props.video);
    // each instance of <ReactJWPlayer> needs a unique id.
    // we randomly generate it here and assign to the container instance.
    this.playerId = uuid.v4();
  }

  onReady(event) {
    // interact with JW Player API here
    // const player = window.jwplayer(this.playerId);
  }
  onAdPlay(event) {
    // track the ad play here
  }
  onVideoLoad(event) {
    this.setState({
      // videoUrl: event.item.videoUrl,
      videoTitle: event.item.description // this only works with json feeds!
    });
  }
  render() {
    // console.log("video props", this.props.video);
    return (
      <div className="react-jw-player-container">
        <ReactJWPlayer
          file={this.props.video}
          //file={JSON.parse(localStorage.getItem("ClickedVideo")).video}
          onReady={this.onReady}
          onVideoLoad={this.onVideoLoad}
          playerId={this.playerId} // bring in the randomly generated playerId
          //playerId={JSON.parse(localStorage.getItem("ClickedVideo")).id}
          playerScript="https://cdn.jwplayer.com/libraries/dxWuqtY1.js"
        />
        {/* <h1>{this.state.videoTitle}</h1> */}
      </div>
    );
  }
}

VideoPlayer.propTypes = propTypes;
// VideoPlayer.defaultProps = defaultProps;
VideoPlayer.displayName = displayName;
export default VideoPlayer;
