import React, {Component} from "react";

class EsewaSuccess extends Component {
    render() {
       return (
           <React.Fragment>
               <h1>Success</h1>
           </React.Fragment>
       ) 
    }
}

export default EsewaSuccess;