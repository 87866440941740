import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import axios from 'axios';
import {Button, Modal} from 'react-bootstrap';
import playstore from '../../../assets/google-play-store-10.jpg';
import appstore from '../../../assets/app-store-icon.png';
import logo from '../../../assets/logo.png';

export default class featuredMusicVideo extends Component {
    constructor(props) {
        super(props);
        this.state={
            videos:[],
            name:'',
            isLoading:false
        };
        
    }
    fetchVideos =() =>{
       this.Setstate={
           videos:[],
           isLoading:false
       };
       axios.get("https://mnm.nettvnepal.com.np/api/net-tv/karaokes/featured?page=2")
       .then(res => 
           {
           if(!this.Unmounted){
              // let a= res.data.music-videos;
               this.setState({
                   videos:res.data.items,
                   isLoading:true
               })
           }}
       )
    }
    handleShow = () => {
        this.setState({
            show:true
        })
    
      }
      handleClose = () =>{
          this.setState({
            show:false
          })
      }
    
     componentDidMount = () =>{
         this.fetchVideos();
       
     }
    
       componentWillUnmount = () => {
           this.Unmounted = true
       }

    render() {
        const { data } = this.props.location.state;
        // console.log("data890", this.state.videos);
        // Modal Starts
    if(this.state.show){
        return (
          <div className="modal ">
          <Modal
           show={this.state.show} onHide={()=>this.handleClose()}
           centered
           
          >
          <Modal.Header closeButton >
          <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
        </Modal.Header>
  
        <Modal.Body>
          <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
          <div className = "row">
          <div className="col-4">
          </div>
          <div className="col-4">
          <div className = "row">
          <div className="col-6">
          <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
          <img src = {playstore} /> 
          </a>
          </div>
          <div className="col-6">
          <img src = {appstore}/>
          </div>
          </div>
          </div>
          <div className="col-4">
          </div>
          </div>
          <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
          <p style={{textAlign:'center'}}>Scan QR Code</p>
          <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
          <div className="row">
          <div className="col-2"><img className="modalqr" src = {logo} style={{height:'100px', width:'140px'}}/>
          </div>
          <div className="col-2">
          </div>
          <div className="col-2">
          </div>
          </div>
          </div>
          <div className="col-4"></div>
          </div>
        </Modal.Body>
  
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>this.handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
      )
      }
      //Modal Ends
        return (
            <Grid container spacing={4} className="homepageGridContainer">
            <div className = "row" >
            {data.items.map(a => 
                <div key={a.id} className="col-lg-2 col-xl-2 col-md-2 col-sm-4 col-4">
                    <div className="geetInfo1">
                        <img className = "home-musicvideo"
                            onClick={ () => this.handleShow()}
                            
                            src={
                            a.logo
                            } alt = "musicImage"
                            />
                    <div className="truncate cardContent" style={{textAlign:'center',color:'#9fa1a3'}}><p>{a.title}</p></div>
                </div>
            </div>
                        )}
                        {/***********************************Data from API */}
                        {this.state.videos.map(b => 
                            <div key={b.id} className="col-lg-2 col-xl-2 col-md-2 col-sm-4 col-4">
                            <div className="geetInfo1">
                            <img className = "home-musicvideo"
                                onClick={ () => this.handleShow()}
                                
                                src={
                                b.logo
                                } alt = "musicImage"
                                />
                                    <div className="truncate cardContent" style={{textAlign:'center',color:'#9fa1a3'}}><p>{b.title}</p></div>
                                </div>
                                </div>
                                    )}      
            </div>
            </Grid>
        )
    }
}
