import Axios from "axios";
import ApiCallError from "../errors/ApiCallError";

export default async function asyncFetch(url, requestConfig = {}) {
  const response = await Axios(url, requestConfig);
  // .then(
  //   res => {
  //     return res;
  //   },
  //   err => {
  //     console.log(err);
  //   }
  // );

  // console.log(response);
  const isSuccess = response.status >= 200 && response.status <= 206;

  if (isSuccess) {
    return response;
  }
  const error = new ApiCallError(response.statusText, response.status);
  error.response = await response;
  throw error;
}
