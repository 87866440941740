import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import "../styles/searchResult.scss";
import "../styles/videos.css"
import { withRouter } from "react-router-dom";
import Axios from "axios";
import Loader1 from "./loader/loader";
import {Image, Dimensions} from 'react-native';
import { Link } from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap';
import playstore from '../assets/google-play-store-10.jpg';
import appstore from '../assets/app-store-icon.png';
import logo from '../assets/logo.png';
//import { StyleSheet} from 'react-native';

class SearchResult extends Component {
  constructor(props){
    super(props);
    this.state = {
        loadin:false,
        posts:[],
        isLoaded:false,
    };
      }

  fetchPosts = () =>{
    // console.log("i am don")
    Axios.get("https://mnm.nettvnepal.com.np/api/net-tv/home/new?search="+this.props.match.params.fooId)
    .then((resp) => {
            console.warn("resp", resp.data.homepageData)
            this.setState({
              posts:resp.data.homepageData,
              isLoaded:true,
            })
          
          });
  }

  componentDidMount = ()=> {
    this.fetchPosts()
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("method called", prevProps.match.params.fooId)
    // console.log("state called", prevState)

    if(this.props.match.params.fooId !== prevProps.match.params.fooId){
      this.fetchPosts()
    }
  }

  handleShow = () => {
    this.setState({
        show:true
    })

  }
  handleClose = () =>{
      this.setState({
        show:false
      })
  }
  
  render() {
    
    const{posts, isLoaded,hello} = this.state;
    // Modal Starts
   if(this.state.show){
    return (
      <div className="modal ">
      <Modal
       show={this.state.show} onHide={()=>this.handleClose()}
       centered
       
      >
      <Modal.Header closeButton >
      <Modal.Title>Get MOMO- More Music More Movies App</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>Currently, playing feature is only available in apps. Please, download app from PLAYSTORE, APPSTORE</p>
      <div className = "row">
      <div className="col-4">
      </div>
      <div className="col-4">
      <div className = "row">
      <div className="col-6">
      <a href="https://play.google.com/store/apps/details?id=com.newitventure.movienmasti&hl=en" target="_blank">
      <img src = {playstore} alt = "playstore" /> 
      </a>
      </div>
      <div className="col-6">
      <img src = {appstore} alt="appstore" />
      </div>
      </div>
      </div>
      <div className="col-4">
      </div>
      </div>
      <p style={{textAlign:'center', marginBottom:'0px'}}>OR</p>
      <p style={{textAlign:'center'}}>Scan QR Code</p>
      <div className="row">
      <div className="col-4"></div>
      <div className="col-4">
      <div className="row">
      <div className="col-2"><img className="modalqr" alt="qrcode" src = {logo} style={{height:'100px', width:'140px'}}/>
      </div>
      <div className="col-2">
      </div>
      <div className="col-2">
      </div>
      </div>
      </div>
      <div className="col-4"></div>
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={()=>this.handleClose()}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  </div>
  )
  }
  //Modal Ends

    if(isLoaded){
    return (
      <React.Fragment>
      <div className="textChange" style={{justifyContent:'flex-start', fontSize:'1.5rem', fontWeight:'bold', marginBottom:10 ,marginTop:20, marginLeft:33}} className="text-capitalize textChange">
        Search results for:{this.props.match.params.fooId}
      </div>

      <Grid container spacing={5} className="homepageGridContainer">
              {/* {console.log("homepage response", this.state.response)} */}
              {posts.map(d => (
                d.data.map(a=>
                <Grid
                  item
                  md = {3}
                  lg = {2}
                  xs={6}
                  key={a.id}
                  className="homepageGridItem"
                >
                  {d.type === "movie"? 
                    <Link to={{
                      pathname: "/nepali/"+a.title,
                      state:{ 
                        data: a}// your data array of objects
                    }}
                    style={{textDecoration:'none'}}
                    >
                      <div className="videoInfo1" style={{marginBottom:15}}>
                        <img style={{height:'100%', width:'100%'}}
                            src={ a.logo}/>
                        
                        <div className="textChange cardContent" style ={{marginBottom:3,textAlign:'center', fontSize: '1rem', fontWeight:'400' }} >
                        {a.title}
                        <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center',fontSize: '1rem', fontWeight:'400', color:'#e93e22', textTransform:'capitalize' }} >
                        {d.type}
                    
                      </div> 
                        </div>
                        </div>
                    </Link>:
                    <div class="link" onClick={ () => this.handleShow()}>
                      <div className="videoInfo1" style={{marginBottom:15}}>
                        <img style={{height:'100%', width:'100%'}}
                            src={ a.logo}/>
                        
                        <div className="textChange cardContent" style ={{marginBottom:3,textAlign:'center', fontSize: '1rem', fontWeight:'400' }} >
                        {a.title}
                        <div className="textChange truncate cardContent" style ={{padding:"0px 5px;",textAlign:'center',fontSize: '1rem', fontWeight:'400', color:'#e93e22', textTransform:'capitalize' }} >
                        {d.type}
                    
                      </div> 
                        </div>
                        </div>
                    </div>
                  }
                  
                </Grid>
              )))}
            </Grid>
          </React.Fragment>
    );
    }
    else{
      return <Loader1/>
    }
  }
}

export default withRouter(SearchResult);
