import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import UserPhoto from "../../components/user/userPhoto";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
//import { UserContext } from "../../components/userInfo";
import { getUserInfo } from "../../connectivity/api.userInfo";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import "../../styles/userProfile.scss";
//import Topup from "./topup";
//import {fonepay} from "../../connectivity/topup/fonpay";
//import TopupModal from "../modal/topupModal";
import { Redirect } from "react-router-dom";
import firebase from "firebase";
//import Login from "../login/login";
//let index;
import axios from 'axios';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      userRes: "",
      edit: false,
      index: "",
      editNameProf: false,
      editContact: false,
      editLinks: false,
      nameChange: false,
      title: "",
      address: "",
      phone: "",
      links: ["apple", "ball", "cat", "dog"],
      website: "abc.com",
      fullName: "FirstName LastName",
      addressChange: false,
      phoneChange: false,
      webisteChange: false,
      linkChange: false,
      titleChange: false,
      photoUpdated: false,
      selectedPhoto: null,
      link0Change: false,
      link1Change: false,
      link2Change: false,
      link3Change: false,
      packagesResponse: "",
      logOut: false
    };
  }
  componentWillMount() {
    getUserInfo().then(res => {
      // console.log("USER INFO RESPONSE", res);
      this.setState({
        userRes: res.data
      });
      // console.log("The User Detail", this.state.userRes);
    });
  }

  editNameProf = () => {
    if (this.state.editNameProf) {
      this.setState({
        editNameProf: false
      });
    } else {
      this.setState({
        editNameProf: true
        // edit: true
      });
    }
  };

  editContact = () => {
    if (this.state.editContact) {
      this.setState({
        editContact: false
      });
    } else {
      this.setState({
        editContact: true
        // edit: true
      });
    }
  };

  contactChange = e => {
    // console.log("contactChange");
    // console.log("the index is", this.index);

    const { name, value } = e.target;
    // let contactSection = this.state.contactSection;

    // console.log("Name: ", name);
    // console.log("value: ", value);

    switch (name) {
      case "fullName":
        this.setState({
          nameChange: true
        });
        this.state.userRes.first_name = value;
        break;
      case "address":
        this.setState({
          addressChange: true
        });
        this.state.userRes.user_detail.address = value;
        break;
      case "title":
        this.setState({
          titleChange: true
        });
        this.state.userRes.user_detail.title = value;
        break;
      case "phone":
        this.setState({
          phoneChange: true
        });
        this.state.userRes.mobile = value;
        break;
      case "website":
        this.setState({
          websiteChange: true
        });
        this.state.userRes.user_detail.website = value;
        break;
      case "links":
        // this.setState({
        //   linkChange: true
        // });
        this.state.userRes.user_detail.links[0] = value;
        // console.log("link edit is", this.state.linkChange);
        break;
      default:
        break;
    }

    this.setState({ [name]: value }, () =>
      console.log("New State", this.state)
    );

    this.setState({
      edit: true
    });
    // console.log("Edit is", this.state.edit);
    if (!this.state.addressChange) {
      if (this.state.userRes.user_detail === null) {
        return;
      } else {
        this.setState({
          address: this.state.userRes.user_detail.address
        });
        this.state.userRes.user_detail.address = this.state.userRes.user_detail.address;
      }
    } else if (!this.state.phoneChange) {
      this.setState({
        phone: this.state.userRes.mobile
      });
      this.state.userRes.mobile = this.state.userRes.mobile;
    } else if (!this.state.webisteChange) {
      if (this.state.userRes.user_detail === null) {
        return;
      } else {
        this.setState({
          website: this.state.userRes.user_detail.website
        });
        this.state.userRes.user_detail.website = this.state.userRes.user_detail.website;
      }
    } else if (!this.state.nameChange) {
      if (this.state.userRes === null) {
        return;
      } else {
        this.setState({
          fullName: this.state.userRes.first_name
        });
      }
    } else if (!this.state.titleChange) {
      if (this.state.userRes.user_detail === null) {
        return;
      } else {
        this.setState({
          title: this.state.userRes.title
        });
      }
    }
  };

  linkUpdate = index => {
    // console.log("The Index", index);
    this.index = index;
    // this.contactChange();
  };

  updateClicked = e => {
    this.state.userRes.last_name = "LastName";
    // this.state.userRes.token = localStorage.getItem("currentUser");
    // console.log("last name sent is", this.state.userRes.last_name);

    if (this.state.editLinks) {
      this.setState({
        editLinks: false
      });
    } else {
      this.setState({
        editLinks: true
      });
    }
  };

  logoutClicked = async() => {
    localStorage.removeItem("helloToken");
    try{
        await firebase.auth().signOut();
          // console.log("FINE LOGOUT WORKED");
          window.location.replace("/login");
          
          
        }
        catch(error) {
          // console.log(error);
        };
    
    localStorage.removeItem("helloToken1");
    localStorage.removeItem("currentName");
    localStorage.removeItem("Current");
    localStorage.removeItem("deviceID")
    localStorage.removeItem("error")
    localStorage.removeItem("Name");
    localStorage.removeItem("Email");
    localStorage.removeItem("UserId");
    localStorage.removeItem("provider");
    localStorage.removeItem("firebasePhone");
    this.setState({
      logOut: true
    })
    window.location.replace("/login");
  };

  photoChange = event => {
    this.setState({
      selectedPhoto: event.target.files[0]
    });
    // console.log("Selected photo", event.target.files[0])
  };

  uploadImage = () => {
    const data = {
      photo: this.state.selectedPhoto
    }
    axios.post("https://mnm.nettvnepal.com.np/api/net-tv/audios/home", data)
    .then(res => { 
            // console.log("User photo updated response ", res);
          })
         .catch((error) =>{
            //  console.log(error);
         }) 
  }

  showModal = () => {
    // console.log("show modal function")
    this.setState({
      showModal: true
    })
    //fonepay("hello warudo");
  }

  render() {
    const user = localStorage.getItem("currentName");

    if(this.state.logOut) {
      // console.log("REDIRECTED");
      return <Redirect to = "/login"/>
    
    }
    
    return (
      <React.Fragment>
      {
      // console.log("user",localStorage.getItem("User"))
      }
      
      {/**  {this.state.showModal ?
          <TopupModal />
          :
          ""  
        }  */}
        <div className="channelBanner">
          <img
            src={require("../../assets/demoChannelBanner.jpg")}
            alt="Channel Banner"
          />
        </div>
        <Container fixed>
          <Grid container spacing={3} className="userInfoWrapper">
            <Grid item sm={2} xs={12} className="pp">
              {/* <ChannelLogo /> */}
              <UserPhoto />
              {/* <IconButton aria-label="delete" className="editIcon" onClick={this.changePhoto}>
                <i className="material-icons">edit</i>
              </IconButton> */}
              {/* <Button
                color="primary"
                style={{ fontSize: 12, width: "100%", marginTop: 5 }}
                onClick={this.changePhoto}
              >
                Change Photo
              </Button> */}

              {/* <input type="file" onChange={this.photoChange} style={{ fontSize: "12px", margin: "0.8rem 0 0.25rem 0" }} />
              <Button
                variant="contained"
                color="primary"
                onClick={this.uploadImage}
                style={{ paddingTop: 0, paddingBottom: 0, marginTop: 5 }}
              >
                Upload
              </Button> */}
            </Grid>
            <Grid item sm={5} xs={12} className="userInfo">
              <div className="nameProf">
                <div>
                  {this.state.editNameProf ? (
                    <div>
                      <TextField
                        id="outlined-email-input"
                        label={this.state.userRes.first_name}
                        margin="normal"
                        variant="outlined"
                        name="fullName"
                        onChange={this.contactChange}
                      />
                      <TextField
                        id="outlined-email-input"
                        label={
                          this.state.userRes.user_detail === null
                            ? "Title"
                            : this.state.userRes.user_detail.title
                        }
                        margin="normal"
                        variant="outlined"
                        name="title"
                        onChange={this.contactChange}
                      />
                    </div>
                  ) : (
                    <div>
                      <Typography variant="h4" gutterBottom>
                        {/* <UserContext.Consumer>
                          {data => data.first_name}
                        </UserContext.Consumer> */}
                        {this.state.userRes.first_name}
                        {/* <UserContext.Consumer>
                          {data => data.last_name}
                        </UserContext.Consumer> */}
                        <div style={{ display: "none" }}>
                          {this.state.userRes.last_name}
                        </div>
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {this.state.userRes.user_detail === undefined
                          ? ""
                          : this.state.userRes.user_detail.title}
                      </Typography>
                    </div>
                  )}
                </div>
             {/**   <div className="editIcon">
                  <IconButton aria-label="delete" onClick={this.editNameProf}>
                    <i className="material-icons">edit</i>
                  </IconButton>
                </div> */}
              </div>
              <div className="nameProf">
                <div>
                  <Typography
                    variant="h6"
                    style={{ color: "black", marginBottom: "1rem" }}
                    gutterBottom
                  >
                    Contact
                  </Typography>
                  {this.state.editContact ? (
                    <div>
                      <TextField
                        id="outlined-email-input"
                        label={
                          this.state.userRes.user_detail === null
                            ? "Address"
                            : this.state.userRes.user_detail.address
                        }
                        name="address"
                        margin="normal"
                        variant="outlined"
                        onChange={this.contactChange}
                        style={{ marginBottom: 24 }}
                      />
                      {/* <TextField
                        id="outlined-email-input"
                        label="Email"
                        margin="normal"
                        variant="outlined"
                      /> */}
                      {/* <div style={{ marginBottom: "2rem" }}> */}
                      <Typography variant="body2">Email</Typography>
                      <Typography variant="h6" gutterBottom style={{color:'black'}}>
                        {localStorage.getItem("Current")}
                      </Typography>
                      {/* </div> */}
                      <TextField
                        id="outlined-email-input"
                        label="Phone"
                        name="phone"
                        margin="normal"
                        variant="outlined"
                        onChange={this.contactChange}
                      />
                      <TextField
                        id="outlined-email-input"
                        label={
                          this.state.userRes.user_detail === null
                            ? "Website"
                            : this.state.userRes.user_detail.website
                        }
                        name="website"
                        margin="normal"
                        variant="outlined"
                        onChange={this.contactChange}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="userContact">
                        <div style={{ marginBottom: "2rem" }}>
                          <Typography variant="body2">Name</Typography>
                          <Typography variant="h6" gutterBottom style={{color:'black'}}>
                            {/* <UserContext.Consumer>
                              {data => data.address}
                            </UserContext.Consumer> */}
                             {localStorage.getItem("currentName")?localStorage.getItem("currentName"):localStorage.getItem("Name")} 
                            
                          </Typography>
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                          <Typography variant="body2">Email</Typography>
                          <Typography variant="h6" gutterBottom style ={{color:'black'}}>
                          {localStorage.getItem("Current")?localStorage.getItem("Current"):localStorage.getItem("Email")}
                          </Typography>
                        </div>
                        <div style={{ marginBottom: "2rem" }}>
                          <Typography variant="body2">Phone</Typography>
                          <Typography variant="h6" gutterBottom style={{color:'black'}}>
                            {/* {<UserContext.Consumer>
                              {data => data.mobile}
                            </UserContext.Consumer>} */}
                            {localStorage.getItem("firebasePhone") === "null" ?"":localStorage.getItem("firebasePhone")}
                         {/**   {this.state.userRes.user_detail === undefined
                              ? ""
                              : this.state.userRes.mobile} */}
                          </Typography>
                        </div>
                     {/**   <div>
                          <Typography variant="body2">Website</Typography>
                          <Typography variant="h6" gutterBottom>
                            {localStorage.getItem("Current")}
                          </Typography>
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
            {/**    <div className="editIcon">
                  <IconButton aria-label="delete" onClick={this.editContact}>
                    <i className="material-icons">edit</i>
                  </IconButton>
                </div> */}
              </div>
            </Grid>
         {/**   <Grid item sm={5} xs={12}>
              <Topup showModal={this.showModal} />
            </Grid> */}
            <Grid item xs={12} style={{ textAlign: "center"}}>
            <div className = "button-and-centered">
              <Button
                variant="contained"
                color="primary"
                onClick={this.logoutClicked}
                //directTo="login"
              >
                Log Out
              </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default UserProfile;
